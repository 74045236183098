import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import type { UserCookiePreferenceValues } from "@/src/domains/user/components/UserSettings/constant/UserSettingsConstant";
import {
  USER_COOKIE_USAGE_PREFERENCE_NAME,
  USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES,
} from "@/src/domains/user/components/UserSettings/constant/UserSettingsConstant";
import useGetUserSettings from "@/src/domains/user/components/UserSettings/hooks/useGetUserSettings";
import useUpdateUserSettings from "@/src/domains/user/components/UserSettings/hooks/useUpdateUserSettings";
import userSettingsSlice, {
  userCookiePreferencesSelector,
} from "@/src/domains/user/components/UserSettings/state/userSettingsSlice";
import { isLoggedInSelector } from "@/src/stores/user/selectors";

export default function useUserCookiePreference() {
  const { data, isLoading, mutate: mutateUserSettings } = useGetUserSettings();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userCookiePreference = useSelector(userCookiePreferencesSelector);
  const { fetch: updateUserSettings, loading } = useUpdateUserSettings();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      const upstreamPreference = data?.userSettings.find(setting => {
        return setting.name === USER_COOKIE_USAGE_PREFERENCE_NAME;
      });

      if (upstreamPreference) {
        const value = upstreamPreference.value as UserCookiePreferenceValues;
        dispatch(userSettingsSlice.actions.setHasSyncWithUserSettingsBackend(true));
        if (value !== USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES[""]) {
          // Only update the local preference if the upstream preference is not empty
          dispatch(userSettingsSlice.actions.setCookiePreferences(value));
        }
        if (
          userCookiePreference !== USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES[""] &&
          value === USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES[""]
        ) {
          // Overwrite the upstream preference with the local preference if the upstream preference is empty
          // This is to prevent the user from being asked to accept cookies again
          handleUpdateUserCookiePreference(userCookiePreference);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.userSettings, isLoggedIn, isLoading, userCookiePreference]);

  const handleUpdateUserCookiePreference = useCallback(
    async (value: UserCookiePreferenceValues) => {
      if (isLoggedIn) {
        await updateUserSettings({
          settingName: USER_COOKIE_USAGE_PREFERENCE_NAME,
          settingValue: value,
        });
        await mutateUserSettings();
        dispatch(userSettingsSlice.actions.setHasSyncWithUserSettingsBackend(true));
      }
      dispatch(userSettingsSlice.actions.setCookiePreferences(value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUserSettings, mutateUserSettings],
  );
  return {
    preference: userCookiePreference,
    isLoading: isLoggedIn && isLoading,
    isUpdating: loading,
    updateUserCookiePreference: handleUpdateUserCookiePreference,
  };
}
