export type ZendeskToken = null | string;
export type ZendeskCreated = string;

export interface Zendesk {
  zendesk: {
    zendeskToken: ZendeskToken;
    created: ZendeskCreated;
    isZendeskScriptLoaded: boolean;
  };
}

export const zendeskTokenSelector = ({ zendesk }: Zendesk) => zendesk.zendeskToken;
export const zendeskTokenCreated = ({ zendesk }: Zendesk) => zendesk.created;
export const isZendeskLoaded = ({ zendesk }: Zendesk) => zendesk.isZendeskScriptLoaded;
