import { useCallback } from "react";

import * as Constant from "@/src/constants/AppConstant";
import { usePublicAPI } from "@/src/hooks/useApi";

type Payload = {
  email: string;
};
const useForgotPasswordEndpoint = () => {
  const [{ data: forgotResponse, loading: isForgetting, error: forgotError }, postData] = usePublicAPI({
    url: Constant.API_ENDPOINT_PASSWORD_FORGOT,
    method: "POST",
  });

  const forgotPassword = useCallback((data: Payload) => postData({ data }), [postData]);

  return { forgotResponse, isForgetting, forgotError, forgotPassword };
};

export default useForgotPasswordEndpoint;
