import type { Cache } from "swr";

import { ROUTER_PATH_SPACE } from "@/src/constants/AppConstant";
import type { GetSpaceByUrlResponse } from "@/src/domains/content/types/ContentAPITypes";
import { getSpaceCacheKey } from "@/src/hooks/space/useGetSpaceByUrl";
import { getSWRCacheKey } from "@/src/utils/APIUtil";
import formatTemplateString from "@/src/utils/helpers/FormatTemplateStrings";

export function getSpaceUrl(): string | undefined {
  const formattedTemplateString = formatTemplateString<{ spaceId: string }>(
    window.location.pathname,
    ROUTER_PATH_SPACE,
  );

  return formattedTemplateString?.spaceId;
}

export const getCurrentSpaceCachedData = (options: {
  cache: Cache;
  userId?: number;
}): GetSpaceByUrlResponse | undefined => {
  const { cache, userId } = options;

  const spaceUrl = getSpaceUrl();
  const currentSpaceCacheKeys = getSpaceCacheKey({
    userId,
    spaceUrl,
  });

  if (currentSpaceCacheKeys) {
    const cacheKey = getSWRCacheKey(currentSpaceCacheKeys);
    const cachedData = cache.get(cacheKey);

    return cachedData?.data;
  }
};
