import type { CSSProperties, DetailedHTMLProps, HTMLAttributes, MouseEventHandler, PropsWithChildren } from "react";
import { forwardRef, useEffect } from "react";

import * as RadixDialog from "@radix-ui/react-dialog";
import type { PointerDownOutsideEvent } from "@radix-ui/react-dismissable-layer";
import clsx from "clsx";

import { SEMBLY_TOAST_CLASS } from "@/src/components/sembly-ui/components/SemblyToast/SemblyToastContainer";
import DialogHeaderWithSideActions from "@/src/components/sembly-ui/core/BaseComponent/Dialog/DialogHeaderWithSideActions";
import useEscapeDialogFocusTrapListener from "@/src/components/sembly-ui/core/BaseComponent/Dialog/useEscapeDialogFocusTrapListener";
import { ChevronBigLeftIcon, CrossIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { cn } from "@/src/utils/cn";

export const DIALOG_CLOSE_BUTTON_ID = "dialog-close";

export interface DialogProps extends PropsWithChildren<RadixDialog.DialogProps> {
  onClose: () => void;
  overlayStyle?: CSSProperties;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
  withTransition?: boolean;
  withOverlay?: boolean;
}

/**
 * Main element of the dialog. Needed for the dialog root component.
 */
const Dialog = (props: DialogProps) => {
  const {
    children,
    overlayStyle,
    onOpenChange,
    onClose,
    withTransition = true,
    withOverlay = true,
    initialFocus,
    ...rootProps
  } = props;

  const handleOnOpenChange = (isOpen: boolean) => {
    if (!isOpen && onClose) onClose();
    onOpenChange && onOpenChange(isOpen);
  };

  useEffect(() => {
    if (props.open) {
      window.requestAnimationFrame(() => {
        initialFocus?.current?.focus();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <RadixDialog.Root onOpenChange={handleOnOpenChange} {...rootProps}>
      <RadixDialog.Portal
        /**
         * Used to force disable animation style name checking in node_modules/@radix-ui/react-presence.
         * Set this to true to skip unnecessary browser reflow (https://github.com/radix-ui/primitives/issues/1634)
         */
        ignoreAnimationName
      >
        <RadixDialog.Overlay
          /**
           * Used to force disable animation style name checking in node_modules/@radix-ui/react-presence.
           * Set this to true to skip unnecessary browser reflow (https://github.com/radix-ui/primitives/issues/1634)
           */
          ignoreAnimationName
          style={overlayStyle}
          className={clsx("safe-h-screen fixed inset-0 z-1300  w-screen", "overlay flex items-center justify-center", {
            "animate-dialog-overlay-appear": withTransition,
            "bg-black/30": withOverlay,
          })}
        >
          {children}
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

interface DialogContainerProps extends RadixDialog.DialogContentProps {
  fillContainer?: boolean;
  fullScreen?: boolean;
  withTransition?: boolean;
}

/**
 * The white container cards of the dialog. Accepts props from Radix.
 * There is an extra handling of overriding Radix's behavior to keep our toast interactable.
 * */
export const DialogContainer = forwardRef<HTMLDivElement, DialogContainerProps>((props, ref) => {
  const {
    className,
    fullScreen,
    fillContainer,
    withTransition = true,
    children,
    style,
    onPointerDownOutside,
    ...dialogProps
  } = props;

  // Listen if a toast is added when opening dialog and adjust body style accordingly
  useEscapeDialogFocusTrapListener();

  const handlePointerDownOutside = (e: PointerDownOutsideEvent) => {
    onPointerDownOutside && onPointerDownOutside(e);
    // If clicking toast, don't dismiss the dialog.
    const pointerTarget = e.target as HTMLElement;
    if (Array.from(pointerTarget.classList).includes(SEMBLY_TOAST_CLASS)) {
      e.preventDefault();
    }
  };

  const appliedStyle = fullScreen ? { bottom: "env(safe-area-inset-bottom, 0)", ...style } : style;

  return (
    <RadixDialog.Content
      ref={ref}
      onPointerDownOutside={handlePointerDownOutside}
      className={clsx([
        "fixed z-1300 mx-auto",
        "inline-block bg-white text-left align-middle shadow-xl ",
        { "animate-dialog-appear delay-75": withTransition },
        { "inset-0": fillContainer || fullScreen, "my-8 rounded-xl": !fullScreen },
        className,
      ])}
      style={appliedStyle}
      {...dialogProps}
      /**
       * Used to force disable animation style name checking in node_modules/@radix-ui/react-presence.
       * Set this to true to skip unnecessary browser reflow (https://github.com/radix-ui/primitives/issues/1634)
       */
      ignoreAnimationName
    >
      {children}
    </RadixDialog.Content>
  );
});
DialogContainer.displayName = "DialogContainer";

type DialogHeaderProps = {
  showDivider?: boolean;
  showClose?: boolean;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  onClose?: () => void;
  className?: string;
  closeButtonClassName?: string;
};

/** The header of the dialog. Includes optional close button */
export const DialogHeader = (props: PropsWithChildren<DialogHeaderProps>) => {
  const handleBackButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (props.onBackButtonClick) {
      e.preventDefault();
      e.stopPropagation();
      props.onBackButtonClick();
    }
  };
  const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (props.onClose) {
      e.preventDefault();
      e.stopPropagation();
      props.onClose();
    }
  };
  return (
    <RadixDialog.Title
      className={cn(
        "flex h-48 min-w-[200px] items-center justify-between py-14 pl-16 pr-14 sm:h-auto sm:px-0 sm:pb-16 sm:pt-0 md:min-w-[400px]",
        "text-left text-sembly-gray",
        {
          "border-b border-light-gray-100": props.showDivider,
        },
        props.className,
      )}
    >
      {props.showBackButton && (
        <RadixDialog.Close
          onClick={handleBackButtonClick}
          className={clsx("rounded-sm text-center text-sembly-gray ring-offset-2")}
        >
          <ChevronBigLeftIcon className="mr-8 h-20 w-20" />
        </RadixDialog.Close>
      )}
      <div className="line-clamp-1 w-full text-subhead-2 sm:text-headline-6">{props.children}</div>
      {props.showClose && (
        <RadixDialog.Close
          onClick={handleCloseButtonClick}
          className={clsx("rounded-sm text-center text-sembly-gray ring-offset-2", props.closeButtonClassName)}
          id={DIALOG_CLOSE_BUTTON_ID}
        >
          <CrossIcon className="h-20 w-20" />
        </RadixDialog.Close>
      )}
    </RadixDialog.Title>
  );
};

export const DialogBody = (props: PropsWithChildren<RadixDialog.DialogDescriptionProps>) => {
  const { className, children, ...rest } = props;
  return (
    <RadixDialog.Description className={cn("px-16 py-8 text-base sm:px-0", className)} {...rest}>
      {children}
    </RadixDialog.Description>
  );
};

export const DialogFooter = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { className, ...rest } = props;
  return (
    <div
      className={cn("flex flex-col-reverse justify-end gap-4 px-16 pt-8 sm:flex-row sm:px-0", className)}
      {...rest}
    />
  );
};

Dialog.displayName = "Dialog";

const DialogElm = {
  Container: DialogContainer,
  Body: DialogBody,
  Header: DialogHeader,
  HeaderWithActions: DialogHeaderWithSideActions,
  Footer: DialogFooter,
};

export default Object.assign(Dialog, DialogElm);
