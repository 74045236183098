import { useCallback } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "@/src/components/sembly-ui/core";
import { GoogleIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { useAuthentication } from "@/src/hooks/authentication/AuthProvider";
import { sendSignupTrackingEvent } from "@/src/hooks/useSignupTracking";
import { signUpDialogSpaceIdSelector } from "@/src/stores/signupDialog/selectors";

interface GoogleLoginButtonProps {
  className?: string;
}

function GoogleLoginButton({ className }: GoogleLoginButtonProps) {
  const [translate] = useTranslation("landingpage");
  const { loginWithGoogle, loading } = useAuthentication();

  const signUpDialogSpaceId = useSelector(signUpDialogSpaceIdSelector);

  const handleClickLoginWithGoogle = useCallback(() => {
    sendSignupTrackingEvent("CLICK_CONTINUE_WITH_GOOGLE_CTA", {
      spaceId: signUpDialogSpaceId,
    });
    loginWithGoogle();
  }, [loginWithGoogle, signUpDialogSpaceId]);

  return (
    <Button
      variant="secondary"
      size="l"
      fluid
      icon={GoogleIcon}
      disabled={loading}
      loading={loading}
      className={clsx("font-medium", className)}
      onClick={handleClickLoginWithGoogle}
    >
      {translate("signupPage.continueWithGoogle")}
    </Button>
  );
}

export default GoogleLoginButton;
