import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import type { NonOAuthError } from "@react-oauth/google";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import type { AxiosError } from "axios";
import getConfig from "next/config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SemblyToast from "@/src/components/sembly-ui/components/SemblyToast/SemblyToast";
import { API_ENDPOINT_USER_EXTERNAL_LOGIN, QUERY_STRING_PARAMETER_KEY } from "@/src/constants/AppConstant";
import * as Constant from "@/src/constants/AppConstant";
import { invitationTokenSelector } from "@/src/domains/user/components/Invite/state/selectors";
import { EXTERNAL_LOGIN_PROVIDER_TYPE, EXTERNAL_LOGIN_SCOPE } from "@/src/hooks/authentication/constant/AuthConstant";
import type { ExternalLoginPayload, LoginData } from "@/src/hooks/authentication/types/AuthType";
import useLogin from "@/src/hooks/authentication/useLogin";
import { createFetcher } from "@/src/hooks/useBaseFetcher";
import useLogout from "@/src/hooks/useLogout";
import useSignupTracking from "@/src/hooks/useSignupTracking";
import { selectors as signupDialogSelectors, slice as signupDialogSlice } from "@/src/stores/signupDialog";
import { getSpaceUrl } from "@/src/utils/SpaceUtils";
import { appendQueryString } from "@/src/utils/general/QueryStringUtil";
import noop from "@/src/utils/helpers/noop";
import { asyncNoop } from "@/src/utils/helpers/noop";

const { publicRuntimeConfig } = getConfig();
const GOOGLE_CLIENT_ID = publicRuntimeConfig.GOOGLE_CLIENT_ID;

export interface AuthContextState {
  login: ReturnType<typeof useLogin>["login"];
  signup: ReturnType<typeof useLogin>["signup"];
  loginWithGoogle: () => void;
  logout: ReturnType<typeof useLogout>;
  loginWithToken: ReturnType<typeof useLogin>["onSuccessLogin"];
  loading: boolean;
}

export const AuthContext = createContext<AuthContextState>({
  login: asyncNoop,
  loginWithGoogle: noop,
  logout: asyncNoop,
  signup: asyncNoop,
  loginWithToken: asyncNoop,
  loading: false,
});

const BaseAuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const { login, loading: isLoadingSignIn, onSuccessLogin, onLoginError, signup } = useLogin();
  const logout = useLogout();
  const [translate] = useTranslation("landingpage");
  const invitationToken = useSelector(invitationTokenSelector);
  const [isLoadingGoogleAuth, setIsLoadingGoogleAuth] = useState(false);
  const dispatch = useDispatch();
  useSignupTracking();

  const isFromJoinAction = useSelector(signupDialogSelectors.isFromJoinActionSelector);

  const handleJoinAfterSignupPrompt = useCallback(() => {
    const joinFromSignUpQueryString = appendQueryString(window.location.search, {
      [Constant.QUERY_STRING_PARAMETER_KEY.JOIN_FROM_SIGNUP]: Constant.QUERY_STRING_PARAMETER_VALUE.TRUE,
    });
    const currentUrl = window.location.href;
    const newUrl = currentUrl.split("?")[0] + joinFromSignUpQueryString;
    // Construct url that contains joinFromSignUp=true
    window.history.replaceState(null, "", newUrl);
  }, []);

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "popup",
    async onSuccess(tokenResponse) {
      try {
        const externalLoginPayload: ExternalLoginPayload = {
          providerType: EXTERNAL_LOGIN_PROVIDER_TYPE.GOOGLE,
          code: tokenResponse.code,
          scope: EXTERNAL_LOGIN_SCOPE.PROFILE,
          invitationToken: invitationToken?.value?.split("#")?.[0],
        };
        if (isFromJoinAction || invitationToken.type === Constant.INVITATION_TYPES.EMAIL) {
          handleJoinAfterSignupPrompt();
        }
        const fetcher = createFetcher<LoginData, ExternalLoginPayload>();
        const loginData = await fetcher(API_ENDPOINT_USER_EXTERNAL_LOGIN, externalLoginPayload);

        await onSuccessLogin(loginData);
      } catch (error) {
        const handleError = onLoginError();
        handleError(error as AxiosError);
      } finally {
        setIsLoadingGoogleAuth(false);
      }
    },
    onNonOAuthError(nonOAuthError) {
      if ((["popup_failed_to_open", "popup_closed"] as NonOAuthError["type"][]).includes(nonOAuthError.type)) {
        SemblyToast.error(translate("loginPage.googleLoginError"));
      }
      setIsLoadingGoogleAuth(false);
    },
    onError() {
      setIsLoadingGoogleAuth(false);
    },
  });

  const handleLoginWithGoogle = useCallback(() => {
    setIsLoadingGoogleAuth(true);
    loginWithGoogle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This useEffect is to handle the logic where user wants to change their email from
   * the email verification dialog (see src/pages/LandingPage/FormDialog/EmailVerificationDialog.tsx:130)
   */
  useEffect(() => {
    const spaceUrl = getSpaceUrl();
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const shouldShowSignUpDialog = params.get(QUERY_STRING_PARAMETER_KEY.SIGNUP) === "1";
    if (spaceUrl && shouldShowSignUpDialog) {
      dispatch(signupDialogSlice.actions.openSignupDialog());
      params.delete("signup");
      window.history.replaceState(window.history.state, document.title, `?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const authProvider = useMemo(
    () => ({
      login,
      loginWithGoogle: handleLoginWithGoogle,
      logout,
      signup,
      loginWithToken: onSuccessLogin,
      // Ideally we don't want to have this loading state here since it will re-render the whole app
      // But since there's no way to get the async state from the useGoogleLogin hook, we have to do this
      loading: isLoadingGoogleAuth || isLoadingSignIn,
    }),
    [login, handleLoginWithGoogle, logout, signup, onSuccessLogin, isLoadingGoogleAuth, isLoadingSignIn],
  );

  return <AuthContext.Provider value={authProvider}>{children}</AuthContext.Provider>;
};

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BaseAuthProvider>{children}</BaseAuthProvider>
    </GoogleOAuthProvider>
  );
};

export const useAuthentication = () => {
  const authContext = useContext(AuthContext);
  if (typeof authContext === "undefined") {
    throw new Error("useAuthentication must be used within a AuthProvider");
  }

  return authContext;
};

export const withAuthProvider = <P extends object>(Component: React.ComponentType<P>) => {
  const WithAuthProvider = (props: P) => (
    <AuthProvider>
      <Component {...props} />
    </AuthProvider>
  );

  WithAuthProvider.displayName = `withAuthProvider(${Component.displayName || Component.name})`;

  return WithAuthProvider;
};
