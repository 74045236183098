import type { Step } from "@/src/domains/space/components/SpaceListSidebar/types/SpaceProgress";
import type { ValueOf } from "@/src/utils/helpers/TypeUtil";

export const SPACE_TYPE = {
  COMMUNITY: "COMMUNITY_DISCOURSE",
  LIMITED: "LIMITED",
  PRIVATE: "PRIVATE",
  SUBSCRIBE: "BRAND_ADMINISTERED",
} as const;

export type SPACE_TYPE_VALUES = (typeof SPACE_TYPE)[keyof typeof SPACE_TYPE];

export const TYPE_OF_COMMUNITY = {
  LIMITED: "LIMITED_COMMUNITY",
  OPEN: "OPEN_COMMUNITY",
  ADMINISTERED: "BRAND_ADMINISTERED",
};

export const DESIRED_PUBLIC_SPACE_STATE = {
  PILOT: "PILOT",
};

export const SPACE_ELIGIBLE_ACTION = {
  JOIN: "JOIN",
  INVITE: "INVITE",
  WATCH: "WATCH",
  UNWATCH: "UNWATCH",
};

export const SPACE_PRIVILEGE_TYPES = {
  READ_ONLY: "READ_ONLY",
  READ_WRITE: "READ_WRITE",
  FACILITATOR: "FACILITATOR",
  ADMIN: "ADMIN",
  OWNER: "OWNER",
  PROVISIONAL_MEMBER: "PROVISIONAL_MEMBER", //Deprecated
  NONE: "NONE",
  NOT_CONTRIBUTED: "",
} as const;
export type SpacePrivilegeTypes = ValueOf<typeof SPACE_PRIVILEGE_TYPES>;

export const MEMBER_SPACE_ACTION = {
  CONNECT: "CONNECT",
  WITHDRAW: "WITHDRAW",
  MESSAGE: "MESSAGE",
  INVITED: "INVITED",
  REMOVE_CONNECTION: "REMOVE_CONNECTION",
  EDIT_PROFILE: "EDIT_PROFILE",
  PROMOTE_MEMBER: "PROMOTE_MEMBER",
  DEMOTE_MEMBER: "DEMOTE_MEMBER",
  KICK_MEMBER: "KICK_MEMBER",
  FLAG_USER: "FLAG_USER",
  APPOINT_AS_ADMIN: "APPOINT_AS_ADMIN",
  REVOKE_ADMIN: "REVOKE_ADMIN",
  STEP_DOWN_TO_FACILITATOR: "STEP_DOWN_TO_FACILITATOR",
  REQUEST_TO_BE_FACILITATOR: "REQUEST_TO_BE_FACILITATOR",
  APPOINT_AS_FACILITATOR: "APPOINT_AS_FACILITATOR",
  REVOKE_FACILITATOR: "REVOKE_FACILITATOR",
  REQUEST_TO_BE_CORE_CONTRIBUTOR: "REQUEST_TO_BE_CORE_CONTRIBUTOR",
  REVOKE_CORE_CONTRIBUTOR: "REVOKE_CORE_CONTRIBUTOR",
  STEP_DOWN_TO_CORE_CONTRIBUTOR: "STEP_DOWN_TO_CORE_CONTRIBUTOR",
  STEP_DOWN_TO_CONTRIBUTOR: "STEP_DOWN_TO_CONTRIBUTOR",
  STEP_DOWN_TO_COMMUNITY_CONTRIBUTOR: "STEP_DOWN_TO_COMMUNITY_CONTRIBUTOR",
  LEAVE_SPACE: "LEAVE_SPACE",
  REMOVE_FROM_SPACE: "REMOVE_FROM_SPACE",
  MANAGE_BADGES: "MANAGE_BADGES",
  REPORT_USER: "REPORT_USER",
} as const;
export type MemberActionKey = ValueOf<typeof MEMBER_SPACE_ACTION>;

export const MEMBERSHIP_STATUS = {
  INVITED: "INVITED",
  PENDING: "PENDING",
  NONE: "NONE",
  MEMBER: "MEMBER",
};

export const SPACE_STATE = {
  PUBLISHED: "PUBLISHED",
  HIDDEN: "HIDDEN",
  RECRUITING: "RECRUITING",
  PENDING: "PENDING",
  PILOT: "PILOT",
  ESTABLISHED: "ESTABLISHED",
  // @deprecated use pilot instead of this
  PROVISIONAL: "PROVISIONAL",
  PENDING_PUBLISHED: "PENDING_PUBLISHED",
};

export const SPACE_ORDER_AS = {
  FLAT: "FLAT",
  HIERARCHY: "HIERARCHY",
};

export const SPACE_SETTING_MODE = {
  EDIT: "EDIT",
  ADD: "ADD",
  DELETE: "DELETE",
};

export const QUESTION_RESPONSE_TYPES = {
  SHORT_TEXT: "SHORT_TEXT",
  LONG_TEXT: "LONG_TEXT",
};

export const MAXIMUM_SPACE_DESCRIPTION_LENGTH = 500;
export const MAXIMUM_SPACE_REQUIREMENT_LENGTH = 500;
export const MAXIMUM_SPACE_NAME_LENGTH = 50;
export const MAXIMUM_BACKGROUND_ANSWER_LENGTH = 500;
export const MAXIMUM_VOLUNTEER_ANSWER_LENGTH = 1000;
export const MAXIMUM_MERITOCRATIC_USER_MANAGEMENT_REASON_LENGTH = 100;

export const WATCH_ACTION_TYPE = {
  WATCH: "WATCH",
  UNWATCH: "UNWATCH",
};

export const CONTENT_VISIBILITY_REASONS = {
  PRELIMINARY_RESEARCH: "Preliminary Research",
  TECHNICAL_CONTENT: "Technical Content",
  SENSITIVE_CONTENT: "Sensitive Content",
  OTHER: "Other",
};

export const CONTENT_VISIBILITY_VALUES = {
  PUBLIC: "PUBLIC",
  RESTRICTED: "MEMBER_ONLY",
  PRIVATE: "PRIVATE",
} as const;

export const DEFAULT_NUM_PIONEERS_NEEDED = 10;
export const SPACE_PUBLICATION_STATE = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
};

export const DEFAULT_SPACE_PROGRESS = {
  steps: [] as Step[],
  completedSteps: 0,
};

export const FACILITATED_BY = {
  SPACE_MEMBER: "SPACE_MEMBER",
  ORGANIZATION: "ORGANIZATION",
};
