import { useCallback } from "react";

import {
  API_ENDPOINT_OTP_VERIFICATION,
  API_ENDPOINT_RESEND_OTP_VERIFICATION,
  API_ENDPOINT_SIGNUP_VERIFICATION,
} from "@/src/constants/AppConstant";
import useApi, { usePublicAPI } from "@/src/hooks/useApi";
import { useEmailVerificationDialogState } from "@/src/stores/dynamic/emailVerificationDialog";

interface VerificationResponse {
  accessToken: string;
  refreshToken: string;
}

const useVerifyEmail = () => {
  const { accessToken: signupAccessToken } = useEmailVerificationDialogState();
  const [{ data: verificationResponse, loading: isLoading, error: verifyError }, postData] =
    usePublicAPI<VerificationResponse>({
      url: API_ENDPOINT_SIGNUP_VERIFICATION,
      method: "POST",
    });

  const [
    { data: otpVerificationResponse, loading: isLoadingOtpVerification, error: otpVerificationError },
    postVerifyOtp,
  ] = useApi<VerificationResponse>(
    {
      url: API_ENDPOINT_OTP_VERIFICATION,
      method: "POST",
      headers: {
        // Authorization header is needed here to determine which OTP belongs to which user
        authorization: `Bearer ${signupAccessToken}`,
      },
    },
    { manual: true, applyDeviceContext: true },
  );

  const [
    { data: resendOtpVerificationResponse, loading: isLoadingResendOtpVerification, error: resendOtpVerificationError },
    postResendVerifyOtp,
  ] = useApi(
    {
      url: API_ENDPOINT_RESEND_OTP_VERIFICATION,
      method: "POST",
      headers: {
        // Authorization header is needed here to determine which OTP belongs to which user
        authorization: `Bearer ${signupAccessToken}`,
      },
    },
    { manual: true, applyDeviceContext: true },
  );

  const verifyEmail = useCallback((data: { token: string }) => postData({ data }), [postData]);
  const verifyOtp = useCallback((data: { otp: string }) => postVerifyOtp({ data }), [postVerifyOtp]);
  const resendVerifyOtp = useCallback(() => postResendVerifyOtp({ data: { includeOTP: true } }), [postResendVerifyOtp]);

  return {
    verificationResponse,
    isLoading,
    verifyError,
    verifyEmail,
    otpVerificationResponse,
    isLoadingOtpVerification,
    otpVerificationError,
    verifyOtp,
    resendOtpVerificationResponse,
    isLoadingResendOtpVerification,
    resendOtpVerificationError,
    resendVerifyOtp,
  };
};

export default useVerifyEmail;
