import { useCallback } from "react";

import type { Method } from "axios";

import { API_ENDPOINT_UPDATE_USER_SETTING, HTTP_METHOD } from "@/src/constants/AppConstant";
import { useApi } from "@/src/hooks/useApi";

export interface UpdateUserSettingsPayload {
  settingName: string;
  settingValue: string | number | boolean;
}

const useUpdateUserSettings = () => {
  const [{ data, loading, error }, fetchData] = useApi({
    url: API_ENDPOINT_UPDATE_USER_SETTING,
    method: HTTP_METHOD.POST as Method,
  });

  const fetch = useCallback(
    ({ settingName, settingValue }: UpdateUserSettingsPayload) => {
      return fetchData({ data: { settingName, settingValue } });
    },
    [fetchData],
  );

  return { data, loading, error, fetch };
};

export default useUpdateUserSettings;
