import React, { useEffect, useRef } from "react";

import { useFormik } from "formik";
import HttpStatus from "http-status-codes";
import { useTranslation } from "next-i18next";
import * as Yup from "yup";

import Divider from "@/src/components/sembly-ui/components/Divider/Divider";
import SemblyToast from "@/src/components/sembly-ui/components/SemblyToast/SemblyToast";
import TextField from "@/src/components/sembly-ui/components/TextField/TextField";
import Button from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyButton";
import LinkButton from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyLinkButton";
import useForgotPasswordEndpoint from "@/src/hooks/useForgotPasswordEndpoint";
import useMedia from "@/src/hooks/useMedia";
import { captureException } from "@/src/utils/logging/SentryLogging";

export type ForgotPasswordFormProps = {
  handleBackToLogin: () => void;
};

type forgotErrorType = {
  title?: string;
};

const ForgotPasswordForm: React.FC<React.PropsWithChildren<ForgotPasswordFormProps>> = ({ handleBackToLogin }) => {
  const { forgotResponse, isForgetting, forgotError, forgotPassword } = useForgotPasswordEndpoint();

  const [t] = useTranslation(["landingpage", "validation"]);

  const inputRef = useRef<HTMLInputElement>(null);
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("landingpage:validation.email.isEmail"))
      .required(t("landingpage:validation.email.required")),
  });
  const { matchesMobile } = useMedia();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: loginSchema,
    onSubmit: data => {
      forgotPassword(data).catch(e => {
        captureException(e);
        SemblyToast.error(t("landingpage:landingPage.forms.earlyAccessForm.formValidation.signupError"));
      });
    },
  });

  useEffect(() => {
    const error = forgotError as unknown as forgotErrorType;
    if (error && error.title) {
      // error.title is the i18n key
      // @ts-ignore
      const errorMsg = t(t("landingpage:validation.email.isEmail"));
      SemblyToast.error(errorMsg);
    }
  }, [forgotError, t]);

  useEffect(() => {
    if (!matchesMobile) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.select();
        }
      }, 200);
    }
  }, [matchesMobile]);

  return (
    <div>
      {forgotResponse?.status === HttpStatus.OK ? (
        <div>
          <div className="mb-4 text-xl font-bold text-sembly-gray">
            {t("landingpage:landingPage.forms.forgotPasswordForm.doneHeader")}
          </div>
          <div className="mb-4 text-base text-sembly-gray">
            {t("landingpage:landingPage.forms.forgotPasswordForm.doneBody")}
          </div>
          <LinkButton size="l" underline={false} className="mt-24" onClick={handleBackToLogin}>
            {t("landingpage:landingPage.forms.forgotPasswordForm.backToLogin")}
          </LinkButton>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} method="post">
          <div>
            <div className="mb-4 text-xl font-bold text-sembly-gray">
              {t("landingpage:landingPage.forms.forgotPasswordForm.formHeader")}
            </div>
            <div className="">
              {`${t("landingpage:loginPage.or")} `}
              <LinkButton size="m" underline={false} onClick={handleBackToLogin}>
                {t("landingpage:landingPage.forms.forgotPasswordForm.loginLabel")}
              </LinkButton>
            </div>
            <Divider className="my-24" />
            <TextField
              ref={inputRef}
              label={t("landingpage:landingPage.forms.forgotPasswordForm.email")}
              type="input"
              className="mb-16"
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email && formik.touched.email)}
              errorLabel={formik.touched.email ? (formik.errors?.email as string) : ""}
            />
            <Button
              variant="primary"
              size="l"
              fluid={false}
              loading={isForgetting}
              type="submit"
              disabled={formik.dirty ? !formik.isValid : true}
            >
              {t("landingpage:landingPage.forms.forgotPasswordForm.submit")}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

ForgotPasswordForm.displayName = "ForgotPasswordForm";

export default ForgotPasswordForm;
