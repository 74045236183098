import React from "react";

export type DividerProps = {
  text?: string;
  textClassName?: string;
  lineClassName?: string;
  lineHeight?: number;
};

type LineProps = {
  lineClassName?: string;
  lineHeight?: number; // in pixel
};

const Line: React.FC<React.PropsWithChildren<LineProps & React.HTMLProps<HTMLDivElement>>> = ({
  lineClassName,
  lineHeight,
}) => {
  const height = lineHeight ? lineHeight : 1;
  return <div className={`flex-1 bg-light-gray-100 ${lineClassName}`} style={{ height: `${height}px` }} />;
};

const Divider: React.FC<React.PropsWithChildren<DividerProps & React.HTMLProps<HTMLDivElement>>> = ({
  text,
  className,
  textClassName,
  lineClassName,
  lineHeight,
  ...rest
}) => {
  return (
    <div className={`flex w-full items-center justify-center ${className}`} {...rest}>
      <Line lineClassName={lineClassName} lineHeight={lineHeight} />
      {text && (
        <>
          <div className={`text-bold mx-8 text-xs text-gray-80 ${textClassName}`}>{text}</div>
          <Line />
        </>
      )}
    </div>
  );
};

export default Divider;
