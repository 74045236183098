import type { RefObject } from "react";
import { useCallback, useRef } from "react";

export default function useHookWithRefCallback<T>(
  initialValue?: T,
  setup?: (node: T) => void,
): [RefObject<T>, (node: T) => void] {
  const ref = useRef<T>(initialValue || null);

  const setRef = useCallback(
    (node: T) => {
      if (node) {
        setup?.(node);
      }
      // Save a reference to the node
      // @ts-ignore
      ref.current = node;
    },
    [setup],
  );

  return [ref, setRef];
}
