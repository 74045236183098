import jwt_decode from "jwt-decode";

import { API_ENDPOINT_GET_USER_PROFILE_V2 } from "@/src/constants/AppConstant";
import type { UserProfileData } from "@/src/domains/user/components/UserProfile/types/UserProfile";
import type { JWTTokenDecoded } from "@/src/hooks/authentication/types/AuthType";
import type { User } from "@/src/hooks/types";
import { createFetcher } from "@/src/hooks/useBaseFetcher";

export const getUserProfile = async (accessToken: string) => {
  const userData = jwt_decode(accessToken) as JWTTokenDecoded;

  const authenticatedFetcher = createFetcher<{ userData: UserProfileData }, { userId: number }>(accessToken);

  const userProfile = await authenticatedFetcher(API_ENDPOINT_GET_USER_PROFILE_V2, {
    userId: userData.userId,
  });

  const user: Partial<User> = {
    id: userData.userId,
    privilegeType: userData.userPrivilegeType,
  };

  const profileData = userProfile.userData.globalInfo;
  user.avatar = profileData?.photo?.value?.[0] || "";
  user.description = profileData?.profileAttributes?.bio?.value?.[0] || "";

  return user;
};
