/* Copyright (C) Hive OS, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential. For non-commercial use of code, ask author for permission.
 * Written by David Ang <david.angga888@gmail.com>, July 2019
 */
import * as Constant from "@/src/constants/ErrorCodeConstants";

export class TypeValidationError extends TypeError {
  constructor(property) {
    super("Invalid type on property: " + property);
    this.name = "TypeValidationError";
    this.property = property;
  }
}

export class PropertyRequiredError extends TypeError {
  constructor(property) {
    super("Missing required property: " + property);
    this.name = "PropertyRequiredError";
    this.property = property;
  }
}

export class InputPropertyRequiredError extends TypeError {
  constructor(property) {
    super("Input missing required property: " + property);
    this.name = "InputPropertyRequiredError";
    this.property = property;
  }
}

export class RangeValidationError extends RangeError {
  constructor(property) {
    super("Invalid value range on property: " + property);
    this.name = "RangeValidationError";
    this.property = property;
  }
}

export class PropertyInitializingError extends Error {
  constructor(property) {
    super("Initializing property: " + property);
    this.name = "PropertyInitializingError";
    this.property = property;
  }
}

export class ClientError extends Error {
  constructor(props) {
    super(props);
    this._code = props.code;
    this._name = "ClientError";
    if (typeof props.property !== "undefined") {
      this._property = props.property;
    } else {
      this._property = "";
    }
    if (props.code in CLIENT_ERROR_CODES) {
      // if a predefined client side error, we retrieve the error details
      this._title = CLIENT_ERROR_CODES[this._code].title;
      this._message = CLIENT_ERROR_CODES[this._code].message;
    } else {
      if (typeof props.title !== "undefined") {
        this._title = props.title;
      } else {
        this._title = this._code;
      }
      if (typeof props.message !== "undefined") {
        this._message = props.message;
      } else {
        this._message = this._code;
      }
    }
  }

  get code() {
    return this._code;
  }

  get name() {
    return this._name;
  }

  get property() {
    return this._property;
  }

  get title() {
    return this._title;
  }

  get message() {
    return this._message;
  }
}

const CLIENT_ERROR_CODES = {
  // TRANSPORT ERRORS
  // Indicating that the application has experienced error when tyring to contact the backend server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_SERVICE_UNAVAILABLE]: {
    title: "validation:clientErrorCodes.ERROR_CODE_SERVICE_UNAVAILABLE.title",
    message: "validation:clientErrorCodes.ERROR_CODE_SERVICE_UNAVAILABLE.message",
  },
  // APPLICATION ERRORS
  // Indicating a login request is not successful due to invalid username/password combination.
  // Recommended action is to display alert message to the user.
  [Constant.ERROR_CODE_INVALID_USERNAME_PASSWORD]: {
    title: "validation:clientErrorCodes.ERROR_CODE_INVALID_USERNAME_PASSWORD.title",
    message: "validation:clientErrorCodes.ERROR_CODE_INVALID_USERNAME_PASSWORD.message",
  },
  // Indicating a failure to retrieve the list of users.
  // Recommended action is to display alert message to the user, and prompt to try again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_USER_LIST]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_USER_LIST.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_USER_LIST.message",
  },
  // Indicating a failure to get stake data. This may be caused by malformed data returned by the server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_STAKE_DATA_GET]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_STAKE_DATA_GET.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_STAKE_DATA_GET.message",
  },
  // Indicating a failure to set consensus data. This may be caused by malformed data returned by the server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_CONSENSUS_DATA_SET]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_CONSENSUS_DATA_SET.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_CONSENSUS_DATA_SET.message",
  },
  // Indicating a failure to get consensus data. This may be caused by malformed data returned by the server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_CONSENSUS_DATA_GET]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_CONSENSUS_DATA_GET.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_CONSENSUS_DATA_GET.message",
  },
  // Indicating a failure to set space data. This may be caused by malformed data returned by the server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_SPACE_SET]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_SPACE_SET.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_SPACE_SET.message",
  },
  // Indicating a failure to get space data. This may be caused by malformed data returned by the server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_SPACE_GET]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_SPACE_GET.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_SPACE_GET.message",
  },
  // Indicating a failure to get notification count. This may be caused by malformed data returned by the server.
  // Recommended action is to retry again after a cooldown period.
  [Constant.ERROR_CODE_FAILED_NOTIFICATION_COUNT_GET]: {
    title: "validation:clientErrorCodes.ERROR_CODE_FAILED_NOTIFICATION_COUNT_GET.title",
    message: "validation:clientErrorCodes.ERROR_CODE_FAILED_NOTIFICATION_COUNT_GET.message",
  },
  // Indicating search query to be sent to the backend server is not allowed, ex: empty string.
  // Recommended action is to ignore the request.
  [Constant.ERROR_CODE_INVALID_SEARCH_QUERY]: {
    title: "validation:clientErrorCodes.ERROR_CODE_INVALID_SEARCH_QUERY.title",
    message: "validation:clientErrorCodes.ERROR_CODE_INVALID_SEARCH_QUERY.message",
  },
  // SYSTEM ERRORS
  // Indicating more than one calls of same type/purpose to the backend server are being simultaneously requested.
  // Recommended action is to ignore the request.
  [Constant.ERROR_CODE_MULTI_THREADED_REQUESTS_NOT_SUPPORTED]: {
    title: "validation:clientErrorCodes.ERROR_CODE_MULTI_THREADED_REQUESTS_NOT_SUPPORTED.title",
    message: "validation:clientErrorCodes.ERROR_CODE_MULTI_THREADED_REQUESTS_NOT_SUPPORTED.message",
  },
  // Indicating that the client application version is no longer supported by the backend server.
  // Required action is to prevent the use of the application, and force the user to refresh the browser to download the new version. Clear any persistent local cache as well.
  [Constant.ERROR_CODE_APPLICATION_VERSION_EXPIRED]: {
    title: "validation:clientErrorCodes.ERROR_CODE_APPLICATION_VERSION_EXPIRED.title",
    message: "validation:clientErrorCodes.ERROR_CODE_APPLICATION_VERSION_EXPIRED.message",
  },
  // Indicating that the client application version is no longer supported by the backend server. Incompatibility may also occur in user settings.
  // Required action is to prevent the use of the application, log the user out, and force the user to refresh the browser to download the new version. Clear any persistent local cache as well.
  [Constant.ERROR_CODE_APPLICATION_VERSION_SESSION_EXPIRED]: {
    title: "validation:clientErrorCodes.ERROR_CODE_APPLICATION_VERSION_SESSION_EXPIRED.title",
    message: "validation:clientErrorCodes.ERROR_CODE_APPLICATION_VERSION_SESSION_EXPIRED.message",
  },
};
