import { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "@/src/components/sembly-ui/core";
import Dialog from "@/src/components/sembly-ui/core/BaseComponent/Dialog/Dialog";
import { ROUTER_PATH_PRIVACY_POLICY_PAGE } from "@/src/constants/AppConstant";
import { USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES } from "@/src/domains/user/components/UserSettings/constant/UserSettingsConstant";
import useUserCookiePreference from "@/src/domains/user/components/UserSettings/hooks/useUserCookiePreference";
import useUserActivation from "@/src/hooks/useUserActivation";
import { useEmailVerificationDialogState } from "@/src/stores/dynamic/emailVerificationDialog";
import { useLoginDialog } from "@/src/stores/dynamic/loginDialog";
import { isSignUpDialogOpenSelector } from "@/src/stores/signupDialog/selectors";
import noop from "@/src/utils/helpers/noop";

export default function UserCookieConsentDialog() {
  const [t] = useTranslation("user");
  const { preference, isLoading, isUpdating, updateUserCookiePreference } = useUserCookiePreference();
  const acceptAllRef = useRef<HTMLButtonElement>(null);
  const [hasCookieConsent, setHasCookieConsent] = useState(false);
  const isLoginDialogOpen = useLoginDialog(state => state.open);
  const isSignupDialogOpen = useSelector(isSignUpDialogOpenSelector);
  const { isEmailVerificationDialogOpen } = useEmailVerificationDialogState();
  const { isProfileVerificationDialogOpen } = useUserActivation();

  // Let user read the privacy policy page without being interrupted by the cookie consent dialog
  const isPrivacyPolicyPage = window.location.pathname === ROUTER_PATH_PRIVACY_POLICY_PAGE;

  const handleRejectNonEssentialCookies = async () => {
    updateUserCookiePreference(USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES.REJECT_NON_ESSENTIAL);
    setHasCookieConsent(true);
  };

  const handleAcceptAllCookies = async () => {
    updateUserCookiePreference(USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES.ACCEPT_ALL);
    setHasCookieConsent(true);
  };

  const hasOpenDialog =
    isLoginDialogOpen || isSignupDialogOpen || isEmailVerificationDialogOpen || isProfileVerificationDialogOpen;

  if (!isLoading && preference === "" && !hasCookieConsent && !isPrivacyPolicyPage && !hasOpenDialog) {
    return (
      <Dialog open onClose={noop} initialFocus={acceptAllRef}>
        <Dialog.Container className="!bottom-0 mb-0 p-24 !align-bottom text-sembly-gray md:!fixed md:!bottom-20 md:!left-20 md:!align-middle">
          <div className="md:max-w-[484px]">
            <div className="mb-40 space-y-16 text-base">
              <div>{t("settings.cookieConsent.intro")}</div>
              <ul className="list-inside list-disc">
                <li>{t("settings.cookieConsent.list.keepSession")}</li>
                <li>{t("settings.cookieConsent.list.monitorBehaviors")}</li>
                <li>{t("settings.cookieConsent.list.measureAudience")}</li>
              </ul>
              <div>
                {t("settings.cookieConsent.outro")}{" "}
                <a
                  href={ROUTER_PATH_PRIVACY_POLICY_PAGE}
                  target="_blank"
                  rel="noreferrer"
                  className="text-medium-blue-80 underline"
                >
                  {t("settings.cookieConsent.privacyPolicy")}
                </a>
              </div>
            </div>
            <div className="flex w-full flex-col items-center space-y-8 md:flex-row md:space-x-4 md:space-y-0">
              <Button
                type="button"
                variant="outline"
                size="m"
                fluid
                loading={isUpdating}
                onClick={handleRejectNonEssentialCookies}
              >
                {t("settings.rejectNonEssentialCookies")}
              </Button>
              <Button
                type="submit"
                ref={acceptAllRef}
                onClick={handleAcceptAllCookies}
                loading={isUpdating}
                variant="primary"
                size="m"
                fluid
              >
                {t("settings.acceptCookies")}
              </Button>
            </div>
          </div>
        </Dialog.Container>
      </Dialog>
    );
  }
  return null;
}
