import { useMemo } from "react";

import { matchPath, useLocation, useParams } from "react-router-dom";

import {
  QUERY_STRING_PARAMETER_KEY,
  ROUTER_PATH_NODE_PAGE,
  ROUTER_PATH_SPACE_INVITES,
  ROUTER_PATH_SPACE_NODE,
  ROUTER_PATH_SPACE_SECTION_VIEW,
  ROUTER_PATH_SPACE_WITH_PRESENTATION,
  SPACE_PRESENTATION,
} from "@/src/constants/AppConstant";
import { getQueryVariable } from "@/src/utils/helpers/MiscHelper";

export type PresentationType = "all" | "feed" | "drafts" | "section" | "lounge";

interface SpaceUrlParameters {
  spaceUrl: string;
  nodeId?: string;
  highlightedId?: string;
  presentation: PresentationType;
  sectionId?: string;
}

interface RouterParams {
  spaceId: string;
  nodeId?: string;
  presentation?: PresentationType;
  sectionId?: string;
}

export function useSpacePageHighlightedId() {
  const location = useLocation();

  const returnedHighlightedId =
    getQueryVariable(location.search, QUERY_STRING_PARAMETER_KEY.HIGHLIGHT_ID) ||
    getQueryVariable(location.search, QUERY_STRING_PARAMETER_KEY.HIGHLIGHT_ID_SHORT);

  return returnedHighlightedId as string;
}

export function useSpaceUrlParameter() {
  const location = useLocation();
  let returnedSpaceUrl: string;
  const { spaceId: spaceUrl } = useParams<RouterParams>();

  returnedSpaceUrl = spaceUrl;

  if (!returnedSpaceUrl) {
    returnedSpaceUrl = (getQueryVariable(location.search, QUERY_STRING_PARAMETER_KEY.SPACE_URL) as string) || "";
  }

  return returnedSpaceUrl;
}

export function useSpaceUrl() {
  const { spaceId: spaceUrl } = useParams<{ spaceId: string }>();
  if (spaceUrl) {
    // encodeURI needed for getting the param from browser. the space url need to be encode and lower to match BE encoding format.
    // If not there is a possibility that mismatch between the cache key vs the browser space url.
    return encodeURI(spaceUrl);
  } else {
    // Fallback way
    if (window?.location?.pathname) {
      const arr = window?.location?.pathname.split("/");
      if (arr.length >= 3) {
        if (`/${arr[1]}` === ROUTER_PATH_NODE_PAGE) {
          return encodeURI(arr[2]);
        }
      }
    }
    return (getQueryVariable(window?.location?.search, QUERY_STRING_PARAMETER_KEY.SPACE_URL) as string) || "";
  }
}

/**
 * Space landing page is used as standalone page and embedded in browse space.
 * Both use case have different ways of capturing url parameter,
 * - Standalone Page uses url params (/s/:spaceId)
 * - Browse Space uses url query string (/browse?s=[spaceId])
 */
export function useSpacePageUrlParameter(): SpaceUrlParameters {
  const location = useLocation();
  let returnedSpaceUrl: string, returnedNodeId: string, returnedPresentation: PresentationType;
  const match = matchPath<RouterParams>(location.pathname, {
    path: [
      ROUTER_PATH_SPACE_SECTION_VIEW,
      ROUTER_PATH_SPACE_NODE,
      ROUTER_PATH_SPACE_WITH_PRESENTATION,
      ROUTER_PATH_SPACE_INVITES,
    ],
  });
  const { spaceId: spaceUrl, nodeId, presentation, sectionId } = match?.params || {};

  // Space url can be undeinfed if we visit non space page such as homepage
  returnedSpaceUrl = spaceUrl ? encodeURI(spaceUrl) : "";

  returnedNodeId = nodeId || "";
  returnedPresentation = presentation as PresentationType;

  if (!returnedSpaceUrl) {
    returnedSpaceUrl = (getQueryVariable(location.search, QUERY_STRING_PARAMETER_KEY.SPACE_URL) as string) || "";
  }

  if (!returnedNodeId) {
    returnedNodeId = (getQueryVariable(location.search, QUERY_STRING_PARAMETER_KEY.NODE_ID) as string) || "";
  }

  if (!returnedPresentation) {
    returnedPresentation = (getQueryVariable(location.search, QUERY_STRING_PARAMETER_KEY.PRESENTATION) ||
      undefined) as PresentationType;
  }

  const spacePageParameters = useMemo(
    () => ({
      spaceUrl: returnedSpaceUrl,
      nodeId: returnedNodeId,
      presentation: (returnedPresentation || SPACE_PRESENTATION.ALL) as PresentationType,
      sectionId,
    }),
    [returnedSpaceUrl, returnedNodeId, returnedPresentation, sectionId],
  );

  return spacePageParameters;
}
