import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "@/src/components/sembly-ui/core";
import Dialog from "@/src/components/sembly-ui/core/BaseComponent/Dialog/Dialog";
import { APP_CONTACT_EMAIL, ROUTER_PATH_COMMUNITY_CONTENT_POLICY } from "@/src/constants/AppConstant";

export type Types = "user" | "space" | "post";

export interface SuspendedDialogProps {
  onClose: () => void;
  open: boolean;
  subjectName?: string;
  type: Types;
}

export default function SuspendedDialog({ onClose, open, subjectName, type }: SuspendedDialogProps) {
  const { t } = useTranslation("landingpage");

  const CustomTrans = <T,>({ transKey, values }: { transKey: string; values?: T & {} }) => {
    return (
      <Trans i18nKey={`suspension.${transKey}.${type}`} t={t} type={type} values={values} components={{ b: <b /> }} />
    );
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Dialog.Container className="max-w-[532px] p-24 xs:mx-8">
        <Dialog.Header showClose className="pt-0 sm:pt-0">
          <Trans
            i18nKey="suspension.header"
            t={t}
            components={{
              Subject: <CustomTrans transKey="headerSubject" />,
              Action: <CustomTrans transKey="headerAction" />,
            }}
          />
        </Dialog.Header>
        <Dialog.Body className="space-y-8">
          <div>
            <Trans
              i18nKey={"suspension.body.action"}
              t={t}
              components={{
                Action: <CustomTrans transKey="bodyAction" />,
                Subject: <CustomTrans transKey="bodySubject" values={{ subjectName }} />,
                Link: (
                  <Link
                    className="text-medium-purple-100 underline visited:text-medium-purple-100"
                    to={ROUTER_PATH_COMMUNITY_CONTENT_POLICY}
                    target="top"
                  />
                ),
              }}
            />
          </div>
          <div>
            <Trans
              i18nKey={"suspension.body.contact"}
              t={t}
              values={{ email: APP_CONTACT_EMAIL }}
              components={{
                mail: (
                  <a
                    className="text-medium-purple-100 underline visited:text-medium-purple-100"
                    href={`mailto:${APP_CONTACT_EMAIL}`}
                  />
                ),
              }}
            />
          </div>
        </Dialog.Body>
        <Dialog.Footer className="mt-8">
          <Button variant="primary" size="m" className="w-[120px]" withFocusClasses={false} onClick={onClose}>
            {t("suspension.close")}
          </Button>
        </Dialog.Footer>
      </Dialog.Container>
    </Dialog>
  );
}
