import React, { useState } from "react";

import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import ForgotPasswordForm from "@/src/components/container/HeaderContainer/ForgotPasswordForm";
import { DialogHeader, DialogHeaderJoinSpace } from "@/src/components/container/HeaderContainer/OnboardingDialogElm";
import Divider from "@/src/components/sembly-ui/components/Divider/Divider";
import TextField from "@/src/components/sembly-ui/components/TextField/TextField";
import Button from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyButton";
import LinkButton from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyLinkButton";
import { ROUTER_PATH_PASSWORD_FORGOT } from "@/src/constants/AppConstant";
import { selectors as inviteSelectors } from "@/src/domains/user/components/Invite/state";
import GoogleLoginButton from "@/src/domains/user/components/LoginDialog/GoogleLoginButton";
import { useAuthentication } from "@/src/hooks/authentication/AuthProvider";
import AuthenticationDialog, {
  AuthenticationDialogState,
} from "@/src/pages/LandingPage/FormDialog/AuthenticationDialog";
import { captureException } from "@/src/utils/logging/SentryLogging";

interface LoginDialogContentProps {
  handleSignupClick: () => void;
  onLoginSuccessCallback?: (externalLoginData: ExternalLoginData) => void;
  handleClose: () => void;
  isFromJoinAction?: boolean;
  spaceInfo?: {
    spaceIcon?: string;
    spaceName?: string;
    spaceUrl?: string;
    spaceProgress?: number;
    isPrivate?: boolean;
  };
}

type ExternalLoginData = {
  isNewUser?: boolean;
};

const BaseLoginDialogContent: React.ForwardRefRenderFunction<HTMLInputElement, LoginDialogContentProps> = (
  { handleSignupClick, onLoginSuccessCallback, handleClose, isFromJoinAction, spaceInfo },
  ref,
) => {
  const [t] = useTranslation(["landingpage", "validation"]);
  const emailPrefill = useSelector(inviteSelectors.emailPrefillSelector);
  const router = useRouter();
  const [loadingButton, setloadingButton] = useState(false);

  const [isForgotPassword, setIsForgotPassword] = useState(Boolean(router.pathname === ROUTER_PATH_PASSWORD_FORGOT));

  const { login, loading } = useAuthentication();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("landingpage:validation.email.isEmail"))
      .required(t("landingpage:validation.email.required")),
    password: Yup.string().required(t("landingpage:validation.password.required")),
  });

  const formik = useFormik({
    initialValues: {
      email: emailPrefill ?? "",
      password: "",
      // Todo fill invitation token
      invitationToken: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: async data => {
      setloadingButton(true);
      try {
        await login({
          payload: data,
          onSuccess() {
            setloadingButton(true);
            if (onLoginSuccessCallback) {
              onLoginSuccessCallback({ isNewUser: false });
            }
          },
        });
      } catch (error) {
        captureException(error as Error);
      } finally {
        setloadingButton(false);
      }
    },
  });

  const handleDialogClose = () => {
    setIsForgotPassword(false);
    handleClose();
    // Clean up the form after dialog fully closed
    setTimeout(() => {
      formik.resetForm();
    }, 200);
  };

  return (
    <>
      {isFromJoinAction && spaceInfo && !isForgotPassword ? (
        <DialogHeaderJoinSpace
          handleClose={handleDialogClose}
          signUpPrefix={t("landingpage:loginPage.loginToJoin")}
          signUpSublabel={t("landingpage:loginPage.subtitle")}
        />
      ) : (
        <DialogHeader handleClose={handleDialogClose} size="s" />
      )}
      {/* Login Dialog Content */}
      {!isForgotPassword && (
        <div>
          {!isFromJoinAction && (
            <>
              <div className="mb-2 mt-8 text-xl font-bold text-sembly-gray">{t("landingpage:loginPage.login")}</div>
              <div className="text-xs text-gray-80">{t("landingpage:loginPage.subtitle")}</div>
            </>
          )}
          <form onSubmit={formik.handleSubmit} method="post" className="mt-10 md:mt-24">
            <TextField
              ref={ref}
              type="input"
              className="mb-8"
              inputClassName="placeholder:italic !py-6"
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email && formik.touched.email)}
              errorLabel={formik.touched.email ? (formik.errors?.email as string) : ""}
            />
            <TextField
              type="password"
              name="password"
              placeholder="Password"
              inputClassName="placeholder:italic !py-6"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.password && formik.touched.password)}
              errorLabel={formik.touched.password ? (formik.errors?.password as string) : ""}
            />
            <span className="font-xs font-medium text-medium-purple-100 ">
              <LinkButton
                size="s"
                className="hover:underline"
                underline={false}
                onClick={() => setIsForgotPassword(true)}
              >
                {t("landingpage:loginPage.forgotPasswordLabel")}
              </LinkButton>
            </span>
            <Button
              variant="primary"
              size="l"
              fluid={true}
              className="mt-16 h-[33px] !rounded-[5px]"
              disabled={loadingButton || loading}
              loading={loadingButton || loading}
              type="submit"
            >
              {t("landingpage:landingPage.forms.loginForm.login")}
            </Button>
          </form>
          <Divider text={t("landingpage:landingPage.forms.loginForm.or")} className="my-16" />
          <GoogleLoginButton className="h-[33px] !rounded-[5px] !border-gray-80" />
        </div>
      )}

      {/* Forgot Password content */}
      {isForgotPassword && (
        <ForgotPasswordForm
          handleBackToLogin={() => {
            setIsForgotPassword(false);
          }}
        />
      )}

      {!isForgotPassword && (
        <div className="text-s mt-16 text-center">
          {t("landingpage:loginPage.newToSembly")}
          <button onClick={handleSignupClick} className="ml-4 font-medium text-medium-purple-100 hover:underline">
            {t("landingpage:loginPage.signupLabel")}
          </button>
        </div>
      )}
    </>
  );
};

export const LoginDialogContent = React.forwardRef(BaseLoginDialogContent);
export interface LoginDialogProps {
  open: boolean;
  handleClose: () => void;
}

const LoginDialog: React.FC<React.PropsWithChildren<LoginDialogProps>> = ({ open, handleClose }) => {
  return <AuthenticationDialog open={open} onClose={handleClose} initialState={AuthenticationDialogState.LOGIN_FORM} />;
};

LoginDialog.displayName = "LoginDialog";

export default LoginDialog;
