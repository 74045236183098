import HttpStatus from "http-status-codes";

import {
  API_ENDPOINT_AUTH_ZENDESK,
  API_ENDPOINT_INVITE_USER_TO_SPACES_VIA_LINK,
  INVITATION_TYPES,
  ZE_CHAT_REAUTH_ACTION,
  ZE_IDENTIFY_ACTION,
  ZE_WEB_WIDGET,
} from "@/src/constants/AppConstant";
import { createAuthMiddleware } from "@/src/hooks/authentication/middleware/createAuthMiddleware";
import { initiateZendesk } from "@/src/hooks/useAuthZendesk";
import { createFetcher } from "@/src/hooks/useBaseFetcher";
import { slice as zendeskSlice } from "@/src/stores/zendesk";

export const inviteUserViaLinkMiddleware = createAuthMiddleware(async context => {
  const { loginData, invitationToken } = context;
  if (invitationToken && invitationToken.type === INVITATION_TYPES.LINK) {
    const fetch = createFetcher(loginData.accessToken);
    await fetch(API_ENDPOINT_INVITE_USER_TO_SPACES_VIA_LINK, {
      invitationToken: invitationToken.value?.split("#")?.[0],
    });
  }
});

export const zendeskMiddleware = createAuthMiddleware(async context => {
  const { loginData, dispatch } = context;
  const fetch = createFetcher<{ accessToken: string; status: number }, {}>(loginData.accessToken);

  const zendeskAuth = await fetch(API_ENDPOINT_AUTH_ZENDESK, {});

  if (zendeskAuth.status === HttpStatus.OK) {
    window.zEAuthCallback?.(zendeskAuth.accessToken);
    dispatch(zendeskSlice.actions.setToken({ token: zendeskAuth.accessToken }));
    window.zE?.(ZE_WEB_WIDGET, ZE_CHAT_REAUTH_ACTION);
    window.zE?.(ZE_WEB_WIDGET, ZE_IDENTIFY_ACTION, {
      name: context.userData?.displayName,
      email: context.userData?.email,
    });
    initiateZendesk();
  }
});
