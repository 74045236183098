/**
 * A function to format given template string to an record of template values
 * @param path window.location.pathname
 * @param template the route template string e.g "/s/:spaceUrl"
 * @returns a record of the template values e.g `{ spaceUrl: "LLM" }`
 * Example Usage
 * ```
 * const path = "/s/abcd/all";
 * const template = "/s/:spaceUrl/:presentation";
 * // Output: { spaceUrl: 'abcd', presentation: 'all' }
 * const formattedPath = formatTemplateString(path, template);
 * ```
 */
export default function formatTemplateString<T = Record<string, string>>(path: string, template: string) {
  const pattern = new RegExp("^" + template.replace(/\/:\w+/g, "/([^/]+)") + ".*$");
  const matchResult = path.match(pattern);

  if (matchResult) {
    const keys = template.match(/:\w+/g)?.map(key => key.substr(1));
    const values = matchResult.slice(1);

    return keys?.reduce<Record<string, string>>((result, key, index) => {
      result[key] = values[index];
      return result;
    }, {}) as T;
  } else {
    return null;
  }
}
