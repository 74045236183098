import React, { useState } from "react";

import { VisibilityFilledIcon, VisibilityOffIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { cn } from "@/src/utils/cn";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: boolean;
  adornment?: JSX.Element;
  startAdornment?: JSX.Element;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  { adornment, type = "text", className, error = false, startAdornment, ...rest },
  ref,
) {
  const [isVisible, setIsVisible] = useState(false);

  const styleClass = cn({
    "border-dark-red-100": error,
    "border-gray-40 focus:border-sembly-gray focus:outline-none": !error,
    "cursor-not-allowed border-light-gray-100 bg-[#F3F3F3] text-gray-40": rest.disabled,
  });

  const isPassword = type === "password";
  const passwordInputType = isVisible ? "text" : "password";

  const showAdornment = adornment || isPassword;

  return (
    <span className={`relative inline-block w-full`}>
      {startAdornment && (
        <div className="absolute bottom-[50%] left-14 flex h-full translate-y-[50%] transform items-center">
          {startAdornment}
        </div>
      )}
      <input
        ref={ref}
        className={cn(
          "rounded-md border bg-white py-6 text-base",
          styleClass,
          {
            "pl-48": Boolean(startAdornment),
            "pl-16": !startAdornment,
            "pr-40": Boolean(showAdornment),
            "pr-16": !showAdornment,
          },
          className,
        )}
        type={isPassword ? passwordInputType : type}
        {...rest}
      />
      {showAdornment && (
        <div className="absolute bottom-[50%] right-10 flex h-full translate-y-[50%] transform items-center space-x-8">
          {adornment}
          {isPassword && Boolean(rest.value?.toString()?.length) && (
            // IMPORTANT: Do not change the div to a button, it will cause the input to lose focus when the inputs are in a form
            <div role="button" className="text-gray-80" tabIndex={-1} onClick={() => setIsVisible(!isVisible)}>
              {!isVisible ? <VisibilityOffIcon /> : <VisibilityFilledIcon />}
            </div>
          )}
        </div>
      )}
    </span>
  );
});

Input.displayName = "Input";

export default Input;
