import { useCallback } from "react";

import SuspendedDialog from "@/src/domains/moderation/components/SuspendedDialog/SuspendedDialog";
import { useSuspendedDialog, useSuspendedDialogActions } from "@/src/stores/dynamic/suspendedDialog";

export interface UserSuspendedProps {}

export default function UserSuspended(props: UserSuspendedProps) {
  const { closeSuspendedDialog } = useSuspendedDialogActions();

  const handleClose = useCallback(() => {
    closeSuspendedDialog();
  }, [closeSuspendedDialog]);

  const isSuspended = useSuspendedDialog(state => state.open);

  return <>{isSuspended && <SuspendedDialog open onClose={handleClose} type="user" />}</>;
}
