import { useDebugValue, useMemo } from "react";

import type { Slice, SliceCaseReducers, Store } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector";

import { reducerManager, store } from "@/src/stores/createStore";

type Selector<S, T> = {
  (state: S): T;
};

type EqualityFn<T> = {
  (state: T, newState: T): boolean;
};

interface CreateDynamicReducerOptions<S> {
  name: string;
  initialState: S;
  reducers: SliceCaseReducers<S>;
}

type State = object;

type UseStoreState<S> = {
  (): S;
  <T>(selector?: Selector<S, T>, equalityFn?: EqualityFn<T>): T;
};

type DynamicReducer<S, A> = [
  UseStoreState<S>,
  () => A,
  () => Slice<S> & {
    actions: A;
  },
];

export default function createDynamicReducer<S extends State, A extends State>(
  args: CreateDynamicReducerOptions<S>,
): DynamicReducer<S, A> {
  const slice = createSlice({
    name: args.name,
    initialState: args.initialState,
    reducers: args.reducers,
  });

  reducerManager.add(slice);

  const useState = <T>(selector?: Selector<S, T>, equalityFn?: EqualityFn<T>): T => {
    // @ts-ignore — Still not sure how to append a new type to the store, will figure it out on separate PR
    const getState = () => store.getState()?.dynamic?.[slice.name] || args.initialState;
    const defaultSelector: Selector<S, T> = state => state as unknown as T;
    const _slice = useSyncExternalStoreWithSelector<S, T>(
      store.subscribe,
      getState,
      // note: we don't have getServerSideState yet
      getState,
      selector || defaultSelector,
      equalityFn,
    );
    useDebugValue(_slice);
    return _slice;
  };

  const useActions = () => {
    return useMemo(
      () =>
        Object.keys(slice.actions || {}).reduce((prev, next) => {
          return {
            ...prev,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [next]: (payload?: any) => {
              // Detect if the payload is not native browser event
              const hasPayload = !payload?.nativeEvent;
              // @ts-ignore
              const action = slice.actions[next](hasPayload ? payload : undefined);
              (store as Store).dispatch(action);
            },
          };
        }, {}) as A,
      [],
    );
  };

  const useSlice = () => {
    return useMemo(() => slice, []);
  };

  return [useState, useActions, useSlice as () => Slice<S, SliceCaseReducers<S>, string> & { actions: A }];
}
