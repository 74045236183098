import { useEffect } from "react";

import Cookies from "js-cookie";
import { shallowEqual, useSelector } from "react-redux";

import { COOKIES_ACCESS_TOKEN, COOKIES_REFRESH_TOKEN } from "@/src/constants/CookieConstants";
import { userAccessDataSelector } from "@/src/stores/user/selectors";

// This logic is preventing cookie and local storage token out sync for whatever reason there is a possibility that cookie is not available but local storage token is available
function useSyncAuthCookies(forceReload = false) {
  const accessData = useSelector(userAccessDataSelector, shallowEqual);

  useEffect(() => {
    const missMatchAccessToken = accessData.accessToken && !Cookies.get(COOKIES_ACCESS_TOKEN);
    const missMatchRefreshToken = accessData.refreshToken && !Cookies.get(COOKIES_REFRESH_TOKEN);
    if (missMatchAccessToken) {
      Cookies.set(COOKIES_ACCESS_TOKEN, accessData.accessToken);
    }
    if (missMatchRefreshToken) {
      Cookies.set(COOKIES_REFRESH_TOKEN, accessData.accessToken);
    }
    if ((missMatchAccessToken || missMatchRefreshToken) && forceReload) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useSyncAuthCookies;
