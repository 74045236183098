import { useCallback, useEffect, useState } from "react";

import type { AxiosError } from "axios";
import axios from "axios";
import HttpStatus from "http-status-codes";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import SemblyToast from "@/src/components/sembly-ui/components/SemblyToast/SemblyToast";
import {
  API_ENDPOINT_SIGNUP,
  API_ENDPOINT_USER_LOGIN,
  ERROR_CODE_INVALID_USERNAME_PASSWORD,
  ERROR_CODE_SERVICE_UNAVAILABLE,
  ROUTER_PATH_HOME,
} from "@/src/constants/AppConstant";
import { LOGIN_ERROR } from "@/src/constants/ErrorConstant";
import { invitationTokenSelector } from "@/src/domains/user/components/Invite/state/selectors";
import { authMiddleware } from "@/src/hooks/authentication/middleware/registerAuthMiddleware";
import type { JWTTokenDecoded, LoginErrorResponse } from "@/src/hooks/authentication/types/AuthType";
import type {
  InvitationToken,
  LoginData,
  LoginPayload,
  OnError,
  OnLoginSuccess,
  SignupPayload,
} from "@/src/hooks/authentication/types/AuthType";
import type { User } from "@/src/hooks/types";
import { createFetcher } from "@/src/hooks/useBaseFetcher";
import { sendSignupTrackingEvent } from "@/src/hooks/useSignupTracking";
import { getUserProfile } from "@/src/services/getUserProfile";
import { persistor } from "@/src/stores/createStore";
import { useEmailVerificationDialogActions } from "@/src/stores/dynamic/emailVerificationDialog";
import { useLoginDialogActions } from "@/src/stores/dynamic/loginDialog";
import { useSuspendedDialogActions } from "@/src/stores/dynamic/suspendedDialog";
import { isFromJoinActionSelector } from "@/src/stores/signupDialog/selectors";
import userSlice, { setUserCookies } from "@/src/stores/user/slice";
import { getSpaceUrl } from "@/src/utils/SpaceUtils";
import { ClientError } from "@/src/utils/general/CustomError";
import { PUBLIC_PAGE_MATCH, matchPaths } from "@/src/utils/helpers/RouterHelper";
import { getUrlPathName, isSameSiteOrigin } from "@/src/utils/helpers/UrlHelper";
import { captureException } from "@/src/utils/logging/SentryLogging";

/**
 * This hook is used to handle login logic consumed by the `AuthContext`
 * **Please don't use this hook directly in the component
 * Instead, use the `useAuthentication` hook**
 * @returns Public hooks for login
 */
export default function useLogin() {
  // These hooks will be passed to the middleware
  // Do not use these hooks directly in this hook other than dispatching the setToken action
  const dispatch = useDispatch();
  const history = useHistory();
  const { closeLoginDialog } = useLoginDialogActions();
  const { openSuspendedDialog } = useSuspendedDialogActions();
  const [translate] = useTranslation("validation");
  const isFromJoinAction = useSelector(isFromJoinActionSelector);

  const [loginError, setLoginError] = useState<ClientError | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  // If you want to use selector in this hook and pass it to the middleware,
  // make sure that your implementation is required globally
  const invitationToken: InvitationToken = useSelector(invitationTokenSelector);

  const { openEmailVerificationDialog } = useEmailVerificationDialogActions();

  const handleSetToken = useCallback(async (accessToken: string, refreshToken: string, user: Partial<User>) => {
    dispatch(
      userSlice.actions.setToken({
        accessToken,
        refreshToken,
        user,
      }),
    );

    // Since persisted state can be in a "pending" state, we need to flush it first before the page is unloaded
    await persistor.flush();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostSignIn = useCallback(
    (isNewUser: boolean, accessToken: string, refreshToken: string, user: Partial<User>) => {
      return new Promise(resolve => {
        window.addEventListener("unload", () => {
          handleSetToken(accessToken, refreshToken, user);
          resolve(true);
        });

        if (isNewUser) {
          const spaceUrl = getSpaceUrl();

          if (spaceUrl) {
            // if already in space, just do reload to make sure tokens are refreshed
            window.location.reload();
          } else {
            window.location.href = ROUTER_PATH_HOME;
          }
        } else {
          const isPublicPage = matchPaths(window.location.pathname, PUBLIC_PAGE_MATCH);
          const isSameSite = isSameSiteOrigin(window.location.href, window.document.referrer);
          // Only redirect to referrer if referrer is not a public page
          const isReferrerPublicPage = matchPaths(getUrlPathName(window.document.referrer), PUBLIC_PAGE_MATCH);

          const shouldRedirectFromPublicPageToReferrer =
            !isFromJoinAction && isPublicPage && isSameSite && !isReferrerPublicPage;
          if (shouldRedirectFromPublicPageToReferrer) {
            // Redirect to referrer if the referrer page comes from sembly host.
            // It is usually get link from email and user not loggedIn. need to logged in and continue to the email link
            // E.g get link from /home?profile=123 but get redirected to /login because not yet login after login it should redirect back to /home?profile=123
            window.location.href = window.document.referrer;
          } else {
            if (matchPaths(window.location.pathname, PUBLIC_PAGE_MATCH)) {
              window.location.href = ROUTER_PATH_HOME;
            } else {
              window.location.reload();
            }
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFromJoinAction],
  );

  /**
   * All login success logic should be handled here
   * Do not handle any business logic here,
   * If you want to do something after login success, use onSuccess callback
   * If you want to do something that will be used globally, use middleware
   */
  const handleSuccessLogin = useCallback(
    async (data: LoginData, onSuccess?: OnLoginSuccess) => {
      try {
        const { accessToken, refreshToken, status } = data || {};

        if (status && status > 400) {
          setLoginError(
            new ClientError({ code: ERROR_CODE_INVALID_USERNAME_PASSWORD, property: "useAuthentication.login" }),
          );

          throw new Error(ERROR_CODE_INVALID_USERNAME_PASSWORD);
        }

        const user = await getUserProfile(accessToken);

        setUserCookies(accessToken, refreshToken);

        await authMiddleware({ loginData: data, invitationToken, userData: user, history, dispatch });

        if (typeof onSuccess === "function") {
          await onSuccess(data);
        }

        await handlePostSignIn(data.isNewUser, accessToken, refreshToken, user);
      } catch (error) {
        captureException(error as Error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    return () => {
      window.removeEventListener("unload", () => {
        handleSetToken("", "", {});
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginError = useCallback(
    (onError?: OnError) => (error: AxiosError) => {
      captureException(error as Error);

      let errorCode = ERROR_CODE_SERVICE_UNAVAILABLE;
      if (error.response?.status === HttpStatus.UNAUTHORIZED) {
        if (
          axios.isAxiosError(error) &&
          (error.response?.data as LoginErrorResponse | undefined)?.metadata?.errorType === LOGIN_ERROR.USER_SUSPENDED
        ) {
          openSuspendedDialog();
          closeLoginDialog();
          return;
        }

        errorCode = ERROR_CODE_INVALID_USERNAME_PASSWORD;
      }
      const clientError = new ClientError({
        code: errorCode,
        property: "useAuthentication.login",
      });

      setLoginError(clientError);

      SemblyToast.error(translate(clientError.title));

      onError?.(error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleLogin = useCallback(
    async ({
      payload,
      onSuccess,
      onError,
    }: {
      payload: LoginPayload;
      onSuccess?: OnLoginSuccess;
      onError?: OnError;
    }) => {
      setLoginError(null);
      setIsLoading(true);
      const fetcher = createFetcher<LoginData, LoginPayload>();
      return fetcher(API_ENDPOINT_USER_LOGIN, { ...payload })
        .then(res => handleSuccessLogin(res, onSuccess))
        .catch(handleLoginError(onError))
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSignUp = useCallback(
    async (signupPayload: SignupPayload) => {
      try {
        setIsLoading(true);
        sendSignupTrackingEvent("SUBMIT_SIGNUP_FORM");
        const fetcher = createFetcher<{ status: number; accessToken: string; refreshToken: string }, SignupPayload>();
        const signupData = await fetcher(API_ENDPOINT_SIGNUP, signupPayload);
        if (signupData.status === HttpStatus.CREATED) {
          const userData = jwt_decode(signupData.accessToken) as JWTTokenDecoded;
          if (userData?.userVerificationStatus) {
            if (userData?.userVerificationStatus < 100) {
              // if user email is still unverified
              openEmailVerificationDialog({
                email: signupPayload.email,
                userId: userData?.userId,
                accessToken: signupData.accessToken,
              });
            } else {
              // if user email already verified, do login after account creation
              await handleLogin({
                payload: {
                  email: signupPayload.email,
                  password: signupPayload.password,
                },
              });
            }
          }
        }
      } catch (error) {
        captureException(error as Error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [handleLogin, openEmailVerificationDialog],
  );

  return {
    login: handleLogin,
    signup: handleSignUp,
    onSuccessLogin: handleSuccessLogin,
    onLoginError: handleLoginError,
    error: loginError,
    loading: isLoading,
  };
}
