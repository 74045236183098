import type { ReactNode } from "react";
import React from "react";

import clsx from "clsx";

import type { InputProps } from "@/src/components/sembly-ui/components/Input/Input";
import Input from "@/src/components/sembly-ui/components/Input/Input";
import TextArea from "@/src/components/sembly-ui/components/TextArea/TextArea";
import { cn } from "@/src/utils/cn";

export interface TextFieldProps extends InputProps {
  label?: ReactNode;
  errorLabel?: string;
  required?: boolean;
  additionalLabel?: React.ReactNode;
  description?: React.ReactNode;
  fluid?: boolean;
  inputClassName?: string;
  multiLine?: boolean;
  labelWrapperClassName?: string;
  labelClassName?: string;
  descriptionClassName?: string;
  errorClassName?: string;
  // for text area
  disableResize?: boolean;
  rows?: number;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      errorLabel,
      required,
      additionalLabel,
      description,
      className,
      inputClassName,
      fluid = true,
      multiLine = false,
      labelWrapperClassName,
      labelClassName,
      descriptionClassName,
      errorClassName,
      disableResize,
      rows,
      ...props
    },
    ref,
  ) => {
    const newInputClassName = cn([
      "placeholder-shown:italic",
      {
        "w-full": fluid,
      },
      inputClassName || "",
    ]);
    return (
      <div className={className}>
        <div className={cn("relative mb-8 text-base", labelWrapperClassName)}>
          <div>
            <span className={cn("font-medium text-sembly-gray", labelClassName)}>
              {label}
              {additionalLabel}
            </span>
            {required && <span className="ml-2 text-medium-purple-100">*</span>}
          </div>
          {description && <div className={cn("mt-4 text-base text-gray-80", descriptionClassName)}>{description}</div>}
        </div>
        {multiLine && (
          <TextArea
            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            className={newInputClassName}
            disableResize={disableResize}
            rows={rows}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
          />
        )}
        {!multiLine && <Input {...props} className={newInputClassName} ref={ref} />}

        {errorLabel && <div className={clsx("mt-4 text-xs text-dark-red-100", errorClassName)}>{errorLabel}</div>}
      </div>
    );
  },
);

TextField.displayName = "TextField";
export default TextField;
