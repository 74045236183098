export const USER_SIGN_UP_PROGRESS_STEPS = {
  VERIFY_EMAIL: "VERIFY_EMAIL",
  COMPLETE_PROFILE: "COMPLETE_PROFILE",
  JOIN_SPACE: "JOIN_SPACE",
  MAKE_CONTRIBUTION: "MAKE_CONTRIBUTION",
};

export enum UserVerificationStatus {
  UNVERIFIED = 50,
  EMAIL_VERIFIED = 100,
}

export enum UserStatus {
  UNKNOWN = 0,
  PROSPECTIVE = 50,
  ADMITTED = 75,
  SIGNED_UP = 300,
  ONBOARDING_COMPLETED = 400,
}
