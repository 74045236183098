import { inviteUserViaLinkMiddleware, zendeskMiddleware } from "@/src/hooks/authentication/middleware/authMiddleware";
import type { AuthMiddlewareContext } from "@/src/hooks/authentication/types/AuthType";

/**
 * Register your middleware here if you want to do something after login
 */
const middlewares = [inviteUserViaLinkMiddleware, zendeskMiddleware];

export const authMiddleware = async (context: AuthMiddlewareContext) => {
  middlewares.forEach(async middleware => {
    await middleware(context);
  });

  return context;
};
