import type { ChangeEventHandler } from "react";
import { useState } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";

import type { InputProps } from "@/src/components/sembly-ui/components/Input/Input";
import Input from "@/src/components/sembly-ui/components/Input/Input";
import { CheckmarkIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { LoadingSpinnerIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import useHookWithRefCallback from "@/src/hooks/useHookWithRefCallback";
import PasswordStrengthIndicator from "@/src/pages/LandingPage/FormDialog/PasswordStrengthIndicator";
import type { PasswordStrengthResult } from "@/src/utils/auth/authUtils";
import { MINIMUM_PASSWORD_STRENGTH_SCORE, getPasswordStrength } from "@/src/utils/auth/authUtils";

const TIMEOUT_BEFORE_SHOWING_PASSWORD_STRENGTH = 1000;

export default function PasswordInput({
  onChange,
  hideStrengthIndicator,
  shouldShowEmptyError = false,
  validateOnChange = false,
  error,
  ...props
}: InputProps & {
  hideStrengthIndicator?: boolean;
  shouldShowEmptyError?: boolean;
  validateOnChange?: boolean;
}) {
  const [translate] = useTranslation("landingpage");
  const [hasTyped, setHasTyped] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [timer, setTimer] = useHookWithRefCallback<NodeJS.Timeout | null>(null);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrengthResult | null>(null);
  const [isError, setError] = useState(false);

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> = event => {
    setHasTyped(false);
    setIsTyping(true);
    if (timer.current) {
      clearTimeout(timer.current);
      setTimer(null);
    }
    const _timer = setTimeout(() => {
      const _passwordStrength = getPasswordStrength(event.target.value);
      // only validate after user finished typing
      setPasswordStrength(_passwordStrength);
      if (validateOnChange) {
        const isError = Boolean((_passwordStrength?.score || 0) < MINIMUM_PASSWORD_STRENGTH_SCORE);
        setError(isError);
      }
      // set the hasTyped to true to show the password strength result.
      setHasTyped(true);
      // set the isTyping to false to hide the password strength result loading indicator.
      setIsTyping(false);
    }, TIMEOUT_BEFORE_SHOWING_PASSWORD_STRENGTH);

    setTimer(_timer);
    onChange?.(event);
  };

  const hasReachedMinimumPasswordStrength = (passwordStrength?.score || 0) >= MINIMUM_PASSWORD_STRENGTH_SCORE;
  const inputAdornment = (
    <>
      {isTyping && <LoadingSpinnerIcon className="animate-spin text-gray-40" />}
      {hasReachedMinimumPasswordStrength && passwordStrength?.status && !isTyping && (
        <CheckmarkIcon className="text-green-45" />
      )}
    </>
  );
  const shouldShowStrengthRelatedErrorMessage = Boolean(props.value) && isError && hasTyped;
  const shouldShowEmptyErrorMessage = shouldShowEmptyError && !props.value;

  return (
    <div className="flex flex-col items-start">
      <div className="relative w-full">
        <Input
          onChange={handleChangePassword}
          name="password"
          type="password"
          placeholder={translate("landingPage.forms.signupForm.password")}
          className={clsx("w-full !py-6 placeholder:italic", {
            "!border-dark-red-60": shouldShowStrengthRelatedErrorMessage || shouldShowEmptyErrorMessage,
          })}
          error={isError}
          adornment={!hideStrengthIndicator ? inputAdornment : undefined}
          {...props}
        />
      </div>
      {shouldShowStrengthRelatedErrorMessage && (
        <div className="mt-4 text-xs text-dark-red-100">
          <PasswordStrengthIndicator password={props.value as string} />
        </div>
      )}
      {shouldShowEmptyErrorMessage && (
        <div className="mt-4 text-xs text-dark-red-100">{translate("validation.password.required")}</div>
      )}
    </div>
  );
}
