import { FEATURE_CONTROL } from "@/src/constants/FeatureControl";

export const USER_SETTINGS = FEATURE_CONTROL.USER_SETTINGS;

export const USER_SETTINGS_OVERALL = USER_SETTINGS.OVERALL();

export const SHOW_GENERAL_SETTINGS = USER_SETTINGS.CHANGE_EMAIL() || USER_SETTINGS.CHANGE_PASSWORD();
export const SHOW_EMAIL_SETTINGS =
  USER_SETTINGS.EMAIL_NOTIFICATIONS() ||
  USER_SETTINGS.EMAIL_DIGEST() ||
  USER_SETTINGS.NEWSLETTER() ||
  USER_SETTINGS.CONNECTION_EMAILS();

export const SHOW_ACCOUNT_SETTINGS = USER_SETTINGS.ACCOUNT_DEACTIVATION_AND_DELETION();

export const SHOW_CHANGE_EMAIL = USER_SETTINGS.CHANGE_EMAIL();
export const SHOW_CHANGE_PASSWORD = USER_SETTINGS.CHANGE_PASSWORD();

export const SHOW_COOKIE_NOTICE = USER_SETTINGS.COOKIE_NOTICE();

// Follows backend naming
// https://github.com/hiveos-team/hiveos-backend/blob/master/src/org/hiveos/entity/setting/user_general_settings_schema.go#L3
export const USER_SETTING_NAME = {
  EMAIL_NOTIF_FREQ: "email.notificationFreq",
  EMAIL_DIGEST_FREQ: "email.digestFreq",
  EMAIL_ENABLE_NEWSLETTER: "email.enableNewsletter",
  EMAIL_ENABLE_CONNECTION_UPDATE: "email.enableConnectionUpdate",
  USER_COOKIE_USAGE_PREFERENCE_NAME: "cookie.usagePreference",
} as const;

export const USER_COOKIE_USAGE_PREFERENCE_NAME = "cookie.usagePreference";

export const USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES = {
  ACCEPT_ALL: "ACCEPT_ALL",
  REJECT_NON_ESSENTIAL: "REJECT_NON_ESSENTIALS",
  // Not possible to reject all cookies but we keep it here for consistency with backend
  // REJECT_ALL: "REJECT_ALL",
  // "" is used for the case when the user has not yet set a preference
  "": "",
} as const;

export type UserSettingNameKey = keyof typeof USER_SETTING_NAME;
export type UserSettingName = (typeof USER_SETTING_NAME)[UserSettingNameKey];
export type UserCookiePreferenceValues =
  (typeof USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES)[keyof typeof USER_COOKIE_USAGE_PREFERENCE_POSSIBLE_VALUES];

export const EMAIL_NOTIFICATION_FREQUENCY = {
  AUTOMATIC: "AUTOMATIC",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  NEVER: "NEVER",
} as const;

export const EMAIL_DIGEST_FREQUENCY = {
  NEVER: "NEVER",
  WEEKLY: "WEEKLY",
  BIWEEKLY: "BIWEEKLY",
  MONTHLY: "MONTHLY",
} as const;

export const USER_SETTING_ORDER = [
  USER_SETTING_NAME.EMAIL_NOTIF_FREQ,
  USER_SETTING_NAME.EMAIL_DIGEST_FREQ,
  USER_SETTING_NAME.EMAIL_ENABLE_NEWSLETTER,
  USER_SETTING_NAME.EMAIL_ENABLE_CONNECTION_UPDATE,
] as UserSettingName[];

export interface UserSetting {
  name: UserSettingName;
  value: string | boolean | number;
  possibleValues: string[] | boolean[] | number[];
  componentType: "TOGGLE" | "DROPDOWN" | "TEXT";
  displayName: string;
  displayHelp: string;
}
