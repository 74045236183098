import React from "react";

import clsx from "clsx";

import Logo, { type LogoProps } from "@/src/components/sembly-ui/components/Logo/Logo";
import Dialog from "@/src/components/sembly-ui/core/BaseComponent/Dialog/Dialog";

export interface DialogHeaderProps extends LogoProps {
  handleClose?: () => void;
  inFullScreenModal?: boolean;
  className?: string;
}

export const DialogHeader: React.FC<React.PropsWithChildren<DialogHeaderProps>> = ({
  handleClose,
  inFullScreenModal,
  color = "black",
  size,
  withBetaText,
  className,
}) => {
  return (
    <Dialog.Header
      className={clsx("relative", className, {
        "!pt-0 text-center": !inFullScreenModal,
        "!py-[11px]": inFullScreenModal,
      })}
      closeButtonClassName="absolute right-0 top-4"
      onClose={handleClose}
      showClose={typeof handleClose === "function"}
      showDivider={inFullScreenModal}
    >
      <div className="flex flex-1 justify-center">
        <Logo color={color} size={size} withBetaText={withBetaText} />
      </div>
    </Dialog.Header>
  );
};

DialogHeader.displayName = "DialogHeader";

export interface DialogContainerProps {
  children: React.ReactNode;
  className?: string;
  fullScreen?: boolean;
}

export const DialogContainer: React.FC<DialogContainerProps> = ({ children, className, fullScreen }) => {
  return (
    <Dialog.Container
      className={clsx("max-w-[600px] text-left text-sembly-gray sm:p-24", { "p-24": !fullScreen }, className)}
      fullScreen={fullScreen}
    >
      {children}
    </Dialog.Container>
  );
};

DialogContainer.displayName = "DialogContainer";

interface DialogHeaderJoinSpaceProps {
  handleClose?: () => void;
  signUpPrefix: React.ReactNode;
  signUpSublabel: string;
  isJoinSpaceState?: boolean;
}

export const DialogHeaderJoinSpace: React.FC<DialogHeaderJoinSpaceProps> = ({
  handleClose,
  signUpPrefix,
  signUpSublabel,
  isJoinSpaceState,
}) => {
  return (
    <>
      <DialogHeader handleClose={handleClose} size="s" />
      <h4 className="mb-2 text-center text-xl text-sembly-gray">{signUpPrefix}</h4>
      <div
        className={clsx("text-center text-gray-80", {
          "text-base": isJoinSpaceState,
          "text-xs": !isJoinSpaceState,
        })}
      >
        {signUpSublabel}
      </div>
    </>
  );
};

DialogHeaderJoinSpace.displayName = "DialogHeaderJoinSpace";
