import createDynamicReducer from "@/src/stores/createDynamicReducer";

type LoginDialogState = {
  open: boolean;
};

type LoginDialogAction = {
  openLoginDialog: () => void;
  closeLoginDialog: () => void;
};

export const [useLoginDialog, useLoginDialogActions, useLoginDialogSlice] = createDynamicReducer<
  LoginDialogState,
  LoginDialogAction
>({
  name: "LoginDialog",
  initialState: {
    open: false,
  },
  reducers: {
    openLoginDialog: state => {
      state.open = true;
    },
    closeLoginDialog: state => {
      state.open = false;
    },
  },
});
