import React from "react";

import clsx from "clsx";

import type { ButtonBaseProps } from "@/src/components/sembly-ui/core/BaseComponent/Button/types";
import { cn } from "@/src/utils/cn";

export type Variant = "primary" | "secondary";
export interface ILinkButtonProps extends ButtonBaseProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variant?: Variant;
  underline?: boolean;
  disabled?: boolean;
  center?: boolean;
  iconClassName?: string;
}

/**
 * @deprecated
 * Please use LinkButton.tsx, this component will be removed
 */
const LinkButton = React.forwardRef<HTMLAnchorElement, ILinkButtonProps>((props, ref) => {
  const {
    variant,
    fluid,
    size,
    underline,
    icon: Icon,
    iconPosition,
    className,
    iconClassName,
    children,
    disabled,
    center,

    ...rest
  } = props;

  const buttonClass = cn([
    "box-border",
    "relative",
    "items-center",
    "justify-center",
    "inline-flex",
    "focus:ring-2",
    "focus:ring-medium-purple-100",
    "focus:ring-offset-1",
    "focus:ring-opacity-60",
    "space-x-8",
    {
      // Primary Style
      "text-medium-purple-100": variant === "primary",
      //Gray Style
      "text-gray-60": variant === "secondary",
      "text-xs": size === "s",
      "text-base": size === "m" || size === "l",
      "text-l": size === "xl",
    },
    {
      block: fluid,
      "w-full": fluid,
      underline: underline,
      "text-center": center,
    },
    {
      "text-gray-40": disabled,
      "cursor-pointer": !disabled,
      "cursor-not-allowed": disabled,
    },
  ]);

  const iconClass = clsx([
    iconClassName,
    {
      "h-12": size === "s",
      "w-12": size === "s",
      "h-14": size === "m",
      "w-14": size === "m",
      "h-16": size === "l",
      "w-16": size === "l",
    },
  ]);

  return (
    <a ref={ref} className={cn(buttonClass, className)} rel="noopener noreferrer" {...rest}>
      {iconPosition === "left" && Icon && (
        <span className="bg-gray inline-block">{Icon && <Icon className={iconClass} />}</span>
      )}
      <span>{children}</span>
      {iconPosition === "right" && Icon && (
        <span className="inline-block">{Icon && <Icon className={iconClass} />}</span>
      )}
    </a>
  );
});

LinkButton.defaultProps = {
  size: "m",
  variant: "primary",
  fluid: false,
  iconPosition: "left",
  underline: true,
  disabled: false,
  center: true,
};
LinkButton.displayName = "LinkButton";

export default LinkButton;
