import type { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";

import * as Constant from "@/src/constants/AppConstant";
import type { UserStatus, UserVerificationStatus } from "@/src/constants/UserConstant";
import useGetMyUserId from "@/src/domains/user/components/User/hooks/useGetMyUserId";
import { createFetcher } from "@/src/hooks/useBaseFetcher";
import useLogout from "@/src/hooks/useLogout";
import useSWRFetch from "@/src/hooks/useSWRFetch";
import type { RootState } from "@/src/stores/rootReducer";
import { selectors as userSelectors } from "@/src/stores/user";
import { slice as userSlice } from "@/src/stores/user";
import { refreshTokenSelector } from "@/src/stores/user/selectors";

export type STEP_ID = "VERIFY_EMAIL" | "COMPLETE_PROFILE" | "JOIN_SPACE" | "MAKE_CONTRIBUTION";

export type ChecklistStep = {
  id: STEP_ID;
  isStepCompleted?: boolean;
  isStepOptional?: boolean;
};

interface APIResponse {
  completedSteps: number;
  requiredSteps?: number;
  isCloseable?: boolean;
  hideIntroModal: boolean;
  status: number;
  steps: ChecklistStep[];
  userVerificationStatus: UserVerificationStatus;
  userStatus: UserStatus;
  shouldRefreshToken?: boolean;
}

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

interface RefreshTokenPayload {
  token: string;
}

const useUserSignupProgress = () => {
  const userId = useGetMyUserId();
  const isLoggedIn = useSelector<RootState, boolean>(state => userSelectors.isLoggedInSelector(state));
  const refreshToken = useSelector(refreshTokenSelector);
  const dispatch = useDispatch();
  const logout = useLogout();

  const { data, error, mutate } = useSWRFetch<APIResponse, {}>({
    url: Constant.API_ENDPOINT_USER_SIGNUP_PROGRESS,
    cacheKey: isLoggedIn ? [Constant.API_ENDPOINT_USER_SIGNUP_PROGRESS, String(userId), refreshToken] : null,
    payload: {},
    swrOptions: {
      async onSuccess(data) {
        if (data.shouldRefreshToken) {
          try {
            const fetcher = createFetcher<RefreshTokenResponse, RefreshTokenPayload>();
            const token = await fetcher(Constant.API_ENDPOINT_REFRESH_TOKEN, {
              token: refreshToken,
            });
            dispatch(
              userSlice.actions.refreshToken({
                refreshToken: token.refreshToken,
                accessToken: token.accessToken,
              }),
            );
          } catch (error) {
            if ((error as AxiosError)?.response?.status === 400) {
              logout();
            }
          }
        }
      },
    },
  });

  return {
    signupProgress: data,
    isLoading: !data,
    isGetSignupProgressError: Boolean(error),
    updateSignupProgress: mutate,
  };
};

export default useUserSignupProgress;
