import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { UserStatus, UserVerificationStatus } from "@/src/constants/UserConstant";
import useGetProfile from "@/src/domains/user/components/User/hooks/useGetProfile";
import useUserSignupProgress from "@/src/hooks/useUserSignupProgress";
import { isLoggedInSelector } from "@/src/stores/user/selectors";

export default function useUserActivation() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { signupProgress, isLoading } = useUserSignupProgress();
  const { data: profile, isLoading: loading } = useGetProfile();

  const [userActivationLoading, setUserActivationLoading] = useState(true);

  const displayName = profile?.userData?.globalInfo?.profileAttributes?.displayName?.value?.[0] || "";
  const fullName = profile?.userData?.globalInfo?.profileAttributes?.fullName?.value?.[0] || "";

  const [isProfileVerificationDialogOpen, setIsProfileVerificationDialogOpen] = useState(false);

  useEffect(() => {
    if (!loading && !isLoading && profile && signupProgress) {
      const isVerified = signupProgress?.userVerificationStatus === UserVerificationStatus.EMAIL_VERIFIED;
      // No displayName will be marked as non verified and need to fill profile verification dialog
      const isProfileVerified = signupProgress?.userStatus === UserStatus.ONBOARDING_COMPLETED;
      setUserActivationLoading(false);
      setIsProfileVerificationDialogOpen(!isProfileVerified && isVerified);
    }
  }, [loading, isLoading, profile, signupProgress, isLoggedIn, displayName]);

  if (!isLoggedIn) {
    return {
      isProfileVerificationDialogOpen: false,
      displayName: "",
      fullName: "",
      isLoading: false,
    };
  }

  return {
    isProfileVerificationDialogOpen,
    displayName: displayName || "",
    fullName: fullName || "",
    isLoading: userActivationLoading,
  };
}
