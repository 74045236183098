export type PasswordStrengthReason = "MIN_CHAR" | "LOW_SCORE";

export interface PasswordStrengthResult {
  status: boolean;
  score: number;
  reason?: PasswordStrengthReason;
}

export const MINIMUM_PASSWORD_LENGTH = 8;

export const MINIMUM_PASSWORD_STRENGTH_SCORE = 2;

/**
 * Rules:
 * Minimum 8 characters in length
 * Contains 2/4 of the following items:
 * - Uppercase Letters
 * - Lowercase Letters
 * - Numbers
 * - Symbols
 */
export const getPasswordStrength = (password: string): PasswordStrengthResult => {
  const _password = password.trim();
  if (_password.length < MINIMUM_PASSWORD_LENGTH) {
    return {
      status: false,
      score: 0,
      reason: "MIN_CHAR",
    };
  }

  const score = [hasNumeric(_password), hasSymbol(_password), hasLowercase(_password), hasUppercase(_password)].filter(
    Boolean,
  ).length;

  if (score < MINIMUM_PASSWORD_STRENGTH_SCORE) {
    return {
      status: false,
      score,
      reason: "LOW_SCORE",
    };
  }

  return {
    status: true,
    score,
  };
};

export function hasNumeric(password: string): boolean {
  return /\d/.test(password);
}

export function hasSymbol(password: string): boolean {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
}

export function hasUppercase(password: string): boolean {
  return /[A-Z]/.test(password);
}

export function hasLowercase(password: string): boolean {
  return /[a-z]/.test(password);
}

export function hasNonNumeric(password: string): boolean {
  return /[^0-9]/.test(password);
}
