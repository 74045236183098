import { useMemo } from "react";

import clsx from "clsx";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

import type { PasswordStrengthReason } from "@/src/utils/auth/authUtils";
import { getPasswordStrength } from "@/src/utils/auth/authUtils";

interface PasswordStrengthIndicatorProps {
  password: string;
}

const getPasswordStrengthLabel = (t: TFunction<"landingpage">, reason: PasswordStrengthReason) => {
  if (reason === "MIN_CHAR") {
    return t("authentication.passwordStrength.MIN_CHAR");
  }
  return t("authentication.passwordStrength.LOW_SCORE");
};

export default function PasswordStrengthIndicator(props: PasswordStrengthIndicatorProps) {
  const { password } = props;
  const [translate] = useTranslation("landingpage");

  const passwordStrength = useMemo(() => {
    return getPasswordStrength(password);
  }, [password]);

  if (passwordStrength.status) {
    return null;
  }

  return (
    <div className={clsx("flex items-center space-x-4 text-red-50")}>
      {passwordStrength.reason && <div>{getPasswordStrengthLabel(translate, passwordStrength?.reason)}</div>}
    </div>
  );
}
