import React from "react";

import clsx from "clsx";

export interface BarSkeletonProps {
  numBars?: number;
  className?: String;
  style?: React.CSSProperties;
}

const BarSkeleton: React.FC<React.PropsWithChildren<BarSkeletonProps>> = ({ numBars = 3, className, style }) => {
  return (
    <div>
      {[...Array(numBars)].map((data, idx) => {
        return (
          <div
            key={idx}
            className={clsx(
              "mb-12 h-12 animate-pulse rounded-xl bg-light-gray-100 duration-150",
              {
                "w-[50%]": idx % 3 === 0,
                "w-[90%]": idx % 3 !== 0,
              },
              className,
            )}
            style={style}
          />
        );
      })}
    </div>
  );
};

BarSkeleton.displayName = "BarSkeleton";

export default BarSkeleton;
