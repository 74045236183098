import { useEffect } from "react";

import { differenceInMinutes } from "date-fns";
import HttpStatus from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";

import {
  API_ENDPOINT_AUTH_ZENDESK,
  ZE_CLOSE_ACTION,
  ZE_CONTACT_FORM_SHOWN_ACTION,
  ZE_IDENTIFY_ACTION,
  ZE_PREFILL_ACTION,
  ZE_UPDATE_SETTING_ACTION,
  ZE_USER_EVENT,
  ZE_WEB_WIDGET,
  ZE_WEB_WIDGET_ON,
} from "@/src/constants/AppConstant";
import type { ZendeskJwtCallback } from "@/src/hooks/types/zendesk";
import useApi from "@/src/hooks/useApi";
import { selectors as userSelectors } from "@/src/stores/user";
import { selectors as zendeskSelectors, slice as zendeskSlice } from "@/src/stores/zendesk";
import { captureException } from "@/src/utils/logging/SentryLogging";

const TOKEN_EXPIRATION_IN_MIN = 7;
const ticketFormSelector = "div[data-embed=ticketSubmissionForm]";

interface HelpdeskTokenResponse {
  accessToken: string;
}

const optionalString = `<span style="color:#B8B8B9;font-style:italic;font-weight:400;">(optional)</span>`;
const formCopy = [
  {
    selector: "label[data-fieldid=name]",
    labelText: `Name`,
    optional: true,
  },
  {
    selector: "label[data-fieldid=email]",
    labelText: "Email",
  },

  {
    selector: "label[data-fieldid=description]",
    labelText: "Message",
  },
  {
    selector: "div[data-testid=attachment-list-container] label",
    labelText: "Attachments",
    optional: true,
  },
];

function updateZenDeskForm() {
  const webWidgetIframe = document.getElementById(ZE_WEB_WIDGET) as HTMLIFrameElement;
  if (webWidgetIframe) {
    formCopy.forEach(({ selector, labelText, optional }) => {
      const label = webWidgetIframe.contentWindow?.document?.querySelector(`${ticketFormSelector} ${selector}`);
      if (label) {
        label.innerHTML = `<div><strong>${labelText}</strong> ${optional ? optionalString : ""}</div>`;
      }
    });
  }
}
export const initiateZendesk = () => {
  if (window.zE) {
    // close zendesk widget
    window.zE(ZE_WEB_WIDGET, ZE_CLOSE_ACTION);
    // enable choose either zendesk contact form or live chat when logged in
    window.zE(ZE_WEB_WIDGET, ZE_UPDATE_SETTING_ACTION, {
      webWidget: {
        contactForm: {
          suppress: false,
        },
        chat: {
          suppress: false,
        },
      },
    });
  }
};

const useAuthZendesk = () => {
  window.zESettings = {
    webWidget: {
      contactOptions: {
        enabled: true,
      },
      launcher: {
        chatLabel: {
          "*": "Help",
        },
      },
      zIndex: 1300, // 1300 is z index for modal
      offset: {
        mobile: {
          vertical: "54px", // 54px is the height of mobile bottom banner
        },
      },
    },
  };

  const dispatch = useDispatch();

  const [, getToken] = useApi<HelpdeskTokenResponse>({
    url: API_ENDPOINT_AUTH_ZENDESK,
    method: "POST",
  });

  const zendeskToken = useSelector<zendeskSelectors.Zendesk, zendeskSelectors.ZendeskToken>(state =>
    zendeskSelectors.zendeskTokenSelector(state),
  );
  const zendeskTokenCreated = useSelector<zendeskSelectors.Zendesk, zendeskSelectors.ZendeskCreated>(state =>
    zendeskSelectors.zendeskTokenCreated(state),
  );
  const isZendeskLoaded = useSelector<zendeskSelectors.Zendesk, boolean>(state =>
    zendeskSelectors.isZendeskLoaded(state),
  );
  const isLoggedIn = useSelector(userSelectors.isLoggedInSelector);

  const user = useSelector(userSelectors.userSelector);

  useEffect(() => {
    if (isZendeskLoaded && window.zE) {
      let hasTokenExpired = true;
      if (zendeskTokenCreated) {
        const diffInMin = differenceInMinutes(new Date(), new Date(zendeskTokenCreated));
        hasTokenExpired = diffInMin >= TOKEN_EXPIRATION_IN_MIN;
      }

      window.zE(ZE_WEB_WIDGET, ZE_UPDATE_SETTING_ACTION, {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: (callback: ZendeskJwtCallback) => {
                window.zEAuthCallback = callback;
                if (isLoggedIn) {
                  if (!zendeskToken || hasTokenExpired) {
                    // fetch token if none available or has expired
                    getToken()
                      .then(({ data }) => {
                        if (data.status === HttpStatus.OK) {
                          const token = data.accessToken;
                          // eslint-disable-next-line promise/no-callback-in-promise
                          callback(token);
                          dispatch(zendeskSlice.actions.setToken({ token }));
                        }
                      })
                      .catch(e => {
                        captureException(e);
                      });
                  } else {
                    // use existing token if available and not yet expired
                    callback(zendeskToken);
                  }
                }
              },
            },
          },
        },
      });

      const email = user?.email;
      const userName = user?.name;
      if (userName && email) {
        window.zE(ZE_WEB_WIDGET, ZE_IDENTIFY_ACTION, {
          name: userName,
          email,
        });
        window.zE(ZE_WEB_WIDGET, ZE_PREFILL_ACTION, {
          name: {
            value: userName,
          },
          email: {
            value: email,
          },
        });
      }

      if (!isLoggedIn) {
        // switch to contact form only when not logged in
        window.zE(ZE_WEB_WIDGET, ZE_UPDATE_SETTING_ACTION, {
          webWidget: {
            contactForm: {
              suppress: false,
            },
            chat: {
              suppress: true,
            },
          },
        });
      }

      // Change description label text
      window.zE(ZE_WEB_WIDGET_ON, ZE_USER_EVENT, (event: { action: string }) => {
        if (event.action === ZE_CONTACT_FORM_SHOWN_ACTION) {
          updateZenDeskForm();
        }
      });
    }
  }, [isZendeskLoaded, getToken, dispatch, zendeskToken, user, zendeskTokenCreated, isLoggedIn]);
};

export default useAuthZendesk;
