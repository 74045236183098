import createDynamicReducer from "@/src/stores/createDynamicReducer";

type SuspendedDialogState = {
  open: boolean;
};

type SuspendedDialogAction = {
  openSuspendedDialog: () => void;
  closeSuspendedDialog: () => void;
};

export const [useSuspendedDialog, useSuspendedDialogActions, useSuspendedDialogSlice] = createDynamicReducer<
  SuspendedDialogState,
  SuspendedDialogAction
>({
  name: "SuspendedDialog",
  initialState: {
    open: false,
  },
  reducers: {
    openSuspendedDialog: state => {
      state.open = true;
    },
    closeSuspendedDialog: state => {
      state.open = false;
    },
  },
});
