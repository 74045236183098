import { useCallback } from "react";

import { API_ENDPOINT_GET_TOKEN_METADATA } from "@/src/constants/AppConstant";
import { useApi } from "@/src/hooks/useApi";

export default function useTokenMetadata() {
  const [{ data: tokenMetadata, loading, error }, fetch] = useApi({
    url: API_ENDPOINT_GET_TOKEN_METADATA,
    method: "POST",
  });

  const getTokenMetadata = useCallback(
    ({ token, tokenType }: { token: string; tokenType: string }) => fetch({ data: { token, tokenType } }),
    [fetch],
  );

  return { tokenMetadata, loading, error, getTokenMetadata };
}
