import createDynamicReducer from "@/src/stores/createDynamicReducer";

type EmailVerificationDialogState = {
  isEmailVerificationDialogOpen: boolean;
  email?: string;
  userId?: number;
  accessToken?: string;
  isLoading?: boolean;
};

type EmailVerificationDialogAction = {
  openEmailVerificationDialog: (payload: { email: string; userId?: number; accessToken?: string }) => void;
  closeEmailVerificationDialog: () => void;
  resetEmailVerificationData: () => void;
  resetButRetainEmailVerificationData: () => void;
  setIsLoading: (payload: { isLoading: boolean }) => void;
};

const INITIAL_STATE = {
  isEmailVerificationDialogOpen: false,
  email: undefined,
  userId: undefined,
  accessToken: undefined,
  isLoading: false,
};

export const [useEmailVerificationDialogState, useEmailVerificationDialogActions, useEmailVerificationDialogSlice] =
  createDynamicReducer<EmailVerificationDialogState, EmailVerificationDialogAction>({
    name: "EmailVerificationDialog",
    initialState: INITIAL_STATE,
    reducers: {
      openEmailVerificationDialog: (state, { payload }) => {
        state.isEmailVerificationDialogOpen = true;
        if (payload.email) {
          state.email = payload.email;
        }
        if (payload.userId) {
          state.userId = payload.userId;
        }
        if (payload.accessToken) {
          state.accessToken = payload.accessToken;
        }
      },
      closeEmailVerificationDialog: state => {
        state.isEmailVerificationDialogOpen = false;
        state.email = undefined;
        state.userId = undefined;
        state.accessToken = undefined;
      },
      resetEmailVerificationData: state => {
        state.email = undefined;
        state.userId = undefined;
        state.accessToken = undefined;
      },
      resetButRetainEmailVerificationData: state => {
        state.userId = undefined;
        state.accessToken = undefined;
      },
      setIsLoading: (state, { payload }) => {
        state.isLoading = payload.isLoading;
      },
    },
  });
