/* Copyright (C) Hive OS, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential. For non-commercial use of code, ask author for permission.
 * Written by David Ang <david.angga888@gmail.com>, Oct 2019
 */
import React from "react";

import { format } from "date-fns";
import qs from "qs";

import * as Constant from "@/src/constants/AppConstant";
import noop from "@/src/utils/helpers/noop";

// Used by Linkify to convert URL string into <a> tag
export const linkifyDefaultURLComponentDecorator = (href, text, key) => (
  <a href={href} key={key} className={"node-text-inline-href"} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export function setAnimationFrameTimeout(fn, delay, registerCancel = () => {}) {
  const start = new Date().getTime();

  const loop = () => {
    const delta = new Date().getTime() - start;

    if (delta >= delay) {
      fn();
      registerCancel(noop);
      return;
    }

    const raf = window.requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = window.requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
}

export function addToQueryVariable(query, value) {
  const previousQueryObject = qs.parse(query.replace("?", "")) ?? {};
  const newQueryObject = {
    ...previousQueryObject,
    ...value,
  };
  return qs.stringify(newQueryObject);
}

export function getQueryVariable(query = "", variable) {
  const cleanQuery = query.replace("?", "");
  const qsObject = qs.parse(cleanQuery);
  return qsObject[variable] || false;
}

export function retractQueryVariable(query = "", variable) {
  const cleanQuery = query.replace("?", "");
  const qsObject = qs.parse(cleanQuery) ?? {};
  qsObject[variable] = undefined;
  return qs.stringify(qsObject);
}

export function retractMultipleQueryVariable(query = "", variables = []) {
  const cleanQuery = query.replace("?", "");
  const qsObject = qs.parse(cleanQuery) ?? {};

  variables.forEach(varName => {
    qsObject[varName] = undefined;
  });

  return qs.stringify(qsObject);
}

export function getUrlParameters() {
  const urlComponent = getURLComponent(window.location.href);
  const queryVariables = {};
  if (urlComponent) {
    for (const key of Object.keys(Constant.QUERY_STRING_PARAMETER_KEY)) {
      const variable = Constant.QUERY_STRING_PARAMETER_KEY[key];
      const val = getQueryVariable(urlComponent.querystring, variable);
      if (val) {
        queryVariables[variable] = val;
      }
    }
  }
  return queryVariables;
}

// Get component of a URL
// Returns: {
//   pathname: the url path
//   querystring: the query string of the url
// }
export function getURLComponent(url) {
  if (typeof url !== "string") {
    return false;
  }
  const queryStringArr = url.split("?");
  if (queryStringArr.length > 1) {
    return {
      pathname: queryStringArr[0],
      querystring: queryStringArr[1],
    };
  }
  return false;
}

// Scroll the document body such that the element is at the top of the viewport
// Source: https://stackoverflow.com/questions/49820013/javascript-scrollintoview-smooth-scroll-and-offset
export function scrollToWithOffset(element, offset = 0, containerId = "") {
  const container = containerId ? document.getElementById(containerId) : document.body;

  if (container && element) {
    const elementTop = element.getBoundingClientRect().top;
    const offsetPosition = elementTop - offset;

    ((containerId && container) || window).scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

export function formatDateFromTimestamp(timestamp, formatString, decimals = 1000000) {
  return format(new Date(timestamp / decimals), formatString);
}

export function stopPropagation(e) {
  e.stopPropagation();
}

export function scrollTo({ element, offset = 0, containerId = "", scrollContainerId = "" }) {
  const container = containerId ? document.getElementById(containerId) : document.body;
  const scrollContainer = scrollContainerId ? document.getElementById(scrollContainerId) : window;

  if (container && element) {
    const containerTop = container.getBoundingClientRect().top;
    const elementTop = element.getBoundingClientRect().top;
    const containerOffset = containerTop - offset;
    const finalTop = elementTop - containerOffset;

    scrollContainer.scrollTo({
      top: finalTop,
      behavior: "smooth",
    });
  }
}

// Given a string with encoded html entities such as &amp;
// Returns the text in its user readable format
export function decodeHTMLEntities(text) {
  var elem = document.createElement("textarea");
  elem.innerHTML = text;
  return elem.value;
}

// Accepts full name, ex: First Last
// and returns the first character of each name, ex: FL
export function getNameInitial(name) {
  const arr = name ? name.split(" ") : [];
  let initialName = null;
  if (arr.length > 1) initialName = arr[0].charAt(0) + arr[1].charAt(0);
  else if (arr.length === 1) initialName = arr[0].charAt(0).toUpperCase();
  return initialName;
}
