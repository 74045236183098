import { matchPath } from "react-router";

import {
  ROUTER_PATH_ABOUT_PAGE_PUBLIC,
  ROUTER_PATH_CAREERS_PAGE,
  ROUTER_PATH_COMMUNITY_CONTENT_POLICY,
  ROUTER_PATH_FAQ_PUBLIC,
  ROUTER_PATH_HOME,
  ROUTER_PATH_LOGIN,
  ROUTER_PATH_PRIVACY_POLICY_PAGE,
  ROUTER_PATH_SIGNUP,
  ROUTER_PATH_TERMS_OF_SERVICE_PAGE,
} from "@/src/constants/AppConstant";

type RouteList = {
  path: string;
  exact: boolean;
};

export const matchPaths = (pathname: string, routes: RouteList[]) => routes.some(route => matchPath(pathname, route));

export const PUBLIC_PAGE_MATCH = [
  { path: ROUTER_PATH_FAQ_PUBLIC, exact: true },
  { path: ROUTER_PATH_LOGIN, exact: true },
  { path: ROUTER_PATH_SIGNUP, exact: true },
  { path: ROUTER_PATH_CAREERS_PAGE, exact: true },
  { path: ROUTER_PATH_HOME, exact: true },
  { path: ROUTER_PATH_TERMS_OF_SERVICE_PAGE, exact: true },
  { path: ROUTER_PATH_ABOUT_PAGE_PUBLIC, exact: true },
  { path: ROUTER_PATH_PRIVACY_POLICY_PAGE, exact: true },
  { path: ROUTER_PATH_COMMUNITY_CONTENT_POLICY, exact: true },
];
