import React, { useCallback, useEffect, useMemo, useRef } from "react";

import clsx from "clsx";

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  disableResize?: boolean;
  isExpandable?: boolean;
  lineHeight?: number;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      error = false,
      rows = 7,
      cols = 9,
      value,
      onChange,
      disableResize,
      isExpandable,
      lineHeight = 1.4,
      ...rest
    },
    ref,
  ) => {
    const styleClass = useMemo(
      () =>
        clsx({
          "border-red-50": error,
          "focus:border-sembly-gray": !error,
          "resize-none": disableResize,
        }),
      [error, disableResize],
    );

    const innerRef = useRef<HTMLTextAreaElement | null>(null);

    const updateHeight = useCallback(() => {
      if (isExpandable && innerRef !== null && innerRef.current) {
        innerRef.current.style.height = "auto";
        innerRef.current.style.height = `${innerRef.current.scrollHeight + lineHeight}px`;
      }
    }, [isExpandable, lineHeight]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        typeof value === "undefined" && updateHeight();
        onChange && onChange(event);
      },
      [updateHeight, value, onChange],
    );

    const passedRef = (node: HTMLTextAreaElement | null) => {
      innerRef.current = node;
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    };

    useEffect(() => {
      typeof value !== "undefined" && updateHeight();
    }, [value, updateHeight]);

    return (
      <textarea
        ref={passedRef}
        rows={rows}
        cols={cols}
        value={value}
        onChange={isExpandable ? handleChange : onChange}
        className={`rounded-md border border-gray-40 bg-white px-16 py-6 text-base with-custom-scrollbar focus:outline-none ${styleClass} ${className}`}
        {...rest}
      />
    );
  },
);
TextArea.displayName = "TextArea";
export default TextArea;
