import type { AuthMiddleware, AuthMiddlewareContext } from "@/src/hooks/authentication/types/AuthType";
import { captureException } from "@/src/utils/logging/SentryLogging";

export const createAuthMiddleware = (middleware: AuthMiddleware) => async (context: AuthMiddlewareContext) => {
  try {
    await middleware(context);
    return context;
  } catch (error) {
    /**
     * To make sure that all of our middleware works, and if one of them fails,
     * we need to capture the error and throw it again.
     */
    captureException(error as Error);
    throw new Error(`Auth middleware error: ${error}`);
  }
};
