export function simulateEscKeyPress() {
  const event = new KeyboardEvent("keydown", {
    key: "Escape",
    keyCode: 27,
    which: 27,
    bubbles: true,
    cancelable: true,
  });

  document.dispatchEvent(event);
}

export function blurActiveElement() {
  const focusedElement = document.activeElement;
  if (focusedElement instanceof HTMLElement) {
    focusedElement.blur();
  }
}
