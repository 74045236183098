import { useSelector } from "react-redux";

import { API_ENDPOINT_GET_USER_SETTINGS } from "@/src/constants/AppConstant";
import type { UserSetting } from "@/src/domains/user/components/UserSettings/constant/UserSettingsConstant";
import useSWRFetch, { useSWRMutationFetch } from "@/src/hooks/useSWRFetch";
import { isLoggedInSelector, userIdSelector } from "@/src/stores/user/selectors";

const useGetUserSettings = () => {
  const userId = useSelector(userIdSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  return useSWRFetch<{ userSettings: UserSetting[]; isPasswordExists?: boolean }>({
    url: API_ENDPOINT_GET_USER_SETTINGS,
    cacheKey: userId && isLoggedIn ? [API_ENDPOINT_GET_USER_SETTINGS, userId.toString()] : null,
    payload: {},
    swrOptions: {
      keepPreviousData: true,
    },
  });
};

export const useGetUserSettingsManualMutation = () => {
  const userId = useSelector(userIdSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  return useSWRMutationFetch<{ userSettings: UserSetting[]; isPasswordExists?: boolean }>({
    url: API_ENDPOINT_GET_USER_SETTINGS,
    cacheKey: userId && isLoggedIn ? [API_ENDPOINT_GET_USER_SETTINGS, userId.toString()] : null,
    payload: {},
  });
};

export default useGetUserSettings;
