import { useCallback, useEffect, useRef, useSyncExternalStore } from "react";

import { toast } from "react-toastify";

const useEscapeDialogFocusTrap = () => {
  const _previousPointerEventStyle = useRef("");
  const escapeDialogFocusTrap = useCallback(() => {
    _previousPointerEventStyle.current = document.body.style.pointerEvents;
    document.body.style.pointerEvents = "";
  }, []);

  const revertDialogFocusTrap = useCallback(() => {
    document.body.style.pointerEvents = _previousPointerEventStyle.current;
    _previousPointerEventStyle.current = "";
  }, []);

  const removeDialogFocusTrap = useCallback(() => {
    document.body.style.pointerEvents = "";
  }, []);

  return { escapeDialogFocusTrap, revertDialogFocusTrap, removeDialogFocusTrap };
};

/**
 * Radix dialog automatically adding pointer-events:none to the body tag,
 * this will make our toast (created from toastify) unable to receive interaction when a dialog is currently opened.
 *
 * Adding a listener whenever a toast is added to remove the body's pointer-event style
 * and add it back when the toast is dismissed.
 **/
const useEscapeDialogFocusTrapListener = () => {
  const { escapeDialogFocusTrap, revertDialogFocusTrap, removeDialogFocusTrap } = useEscapeDialogFocusTrap();
  const _hasModifiedBodyStyle = useRef(false);

  // callback passed from useSyncExternalStore, using any because it's not clearly defined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subscribe = useCallback((callback: any) => {
    const toastChangeEvent = toast.onChange(payload => {
      if (payload.status === "added") {
        const isBodyPreventPointerEvent = document.body.style.pointerEvents === "none";
        if (isBodyPreventPointerEvent) {
          escapeDialogFocusTrap();
          _hasModifiedBodyStyle.current = true;
        }
      }

      if (payload.status === "removed" && _hasModifiedBodyStyle.current) {
        _hasModifiedBodyStyle.current = false;
        revertDialogFocusTrap();
      }

      callback();
    });

    return () => {
      // toast.onChange returns a function to unsubscribe
      // therefore we call it here for subscription cleanup
      toastChangeEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // Cleanup effect that will run when dialog is closed.
      // Without this, there's a possibility of a race condition that body will
      // got pointer-events: none from the reversion function during toast dismissal.
      if (_hasModifiedBodyStyle.current) {
        removeDialogFocusTrap();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSyncExternalStore(subscribe, () => null);
};

export default useEscapeDialogFocusTrapListener;
