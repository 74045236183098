import React, { useEffect, useId, useRef, useState } from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import SemblyToast from "@/src/components/sembly-ui/components/SemblyToast/SemblyToast";
import TextField from "@/src/components/sembly-ui/components/TextField/TextField";
import Button from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyButton";
import Dialog from "@/src/components/sembly-ui/core/BaseComponent/Dialog/Dialog";
import { useAuthentication } from "@/src/hooks/authentication/AuthProvider";
import useMedia from "@/src/hooks/useMedia";
import { sendSignupTrackingEvent } from "@/src/hooks/useSignupTracking";
import useVerifyEmail from "@/src/hooks/useVerifyEmail";
import {
  useEmailVerificationDialogActions,
  useEmailVerificationDialogState,
} from "@/src/stores/dynamic/emailVerificationDialog";
import { blurActiveElement } from "@/src/utils/helpers/EventUtils";
import noop from "@/src/utils/helpers/noop";
import { captureException } from "@/src/utils/logging/SentryLogging";

const ALERT_DURATION = 5000;
const RESEND_EMAIL_DURATION = 60;

interface CountdownProps {
  onComplete: () => void;
}

const Countdown = ({ onComplete }: CountdownProps) => {
  const [countdown, setCountdown] = useState(RESEND_EMAIL_DURATION);
  const [isCountdownRunning, setIsCountdownRunning] = useState(true);
  const [translate] = useTranslation("landingpage");

  useEffect(() => {
    if (isCountdownRunning) {
      const interval = setInterval(() => {
        setCountdown(countdown - 1);
        if (countdown === 0) {
          setIsCountdownRunning(false);
          onComplete();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountdownRunning, countdown]);

  return (
    <span className="text-sembly-gray">
      ({translate("landingPage.forms.emailVerificationForm.countdown", { countdown })})
    </span>
  );
};

const getOtpVerificationSchema = (t: TFunction<"landingpage">) =>
  Yup.object().shape({
    otp: Yup.string().min(6, t("validation.otp.min")),
  });

interface VerificationDialogProps {
  onSuccessCallback?: () => void;
}

export const EmailVerificationDialogContent = ({ onSuccessCallback }: VerificationDialogProps) => {
  const { verifyOtp, otpVerificationError, resendVerifyOtp } = useVerifyEmail();
  const [disableResend, setDisableResend] = useState(false);
  const { loginWithToken } = useAuthentication();

  const verificationId = useId();
  const verifIdString = `verification-${verificationId}`;
  const [translate] = useTranslation("landingpage");

  const { email, userId, isLoading } = useEmailVerificationDialogState();
  const { resetButRetainEmailVerificationData, setIsLoading } = useEmailVerificationDialogActions();

  const { matchesMobile } = useMedia();

  const otpField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (otpField?.current) {
      blurActiveElement();
      const timer = setTimeout(() => {
        const endOfText = otpField.current?.value?.length || 0;
        otpField.current?.setSelectionRange(endOfText, endOfText);
        otpField.current?.focus();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      sendSignupTrackingEvent("OPEN_EMAIL_VERIFICATION_MODAL", { userId });
      return () => {
        sendSignupTrackingEvent("CLOSE_EMAIL_VERIFICATION_MODAL", { userId });
      };
    }
  }, [userId]);

  const { handleSubmit, handleChange, values, errors, isValid, handleBlur, touched } = useFormik({
    initialValues: {
      otp: "",
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: getOtpVerificationSchema(translate),
    onSubmit: async ({ otp }) => {
      try {
        setIsLoading({ isLoading: true });
        const { data } = await verifyOtp({ otp });
        resetButRetainEmailVerificationData();
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        await loginWithToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          isNewUser: true,
          status: 200,
        });
      } catch (error) {
        captureException(error as Error);
      } finally {
        setIsLoading({ isLoading: false });
      }
    },
  });

  const handleResendOtp = async () => {
    try {
      if (disableResend) {
        return;
      }
      await resendVerifyOtp();
      SemblyToast.success(translate("landingPage.forms.emailVerificationForm.resendOtpSuccess"), {
        autoClose: ALERT_DURATION,
      });
      setDisableResend(true);
    } catch (error) {
      captureException(error as Error);
      SemblyToast.error(translate("landingPage.forms.emailVerificationForm.resendError"), {
        autoClose: ALERT_DURATION,
      });
    }
  };

  const isOtpError = (touched.otp ? Boolean(errors.otp) : false) || Boolean(otpVerificationError);
  const otpErrorMessage = otpVerificationError
    ? translate("landingPage.forms.emailVerificationForm.invalidOtpOrError")
    : errors.otp;

  return (
    <div className="space-y-24 p-16 text-sembly-gray sm:p-0">
      <form onSubmit={handleSubmit} className="flex flex-col space-y-16">
        <div className="flex flex-col">
          <label htmlFor={verifIdString} className="text-base text-sembly-gray">
            <span>{translate("landingPage.forms.emailVerificationForm.label")}</span>{" "}
            <span className="font-bold">{email}</span>
          </label>
          <TextField
            ref={otpField}
            placeholder={translate("landingPage.forms.emailVerificationForm.placeholder")}
            id={verifIdString}
            type="text"
            name="otp"
            onChange={handleChange}
            onBlur={handleBlur}
            error={isOtpError}
            errorLabel={isOtpError ? otpErrorMessage : ""}
            fluid
            inputClassName={clsx("!py-6 placeholder-shown:italic", {
              "!border-dark-red-60": isOtpError,
            })}
          />
        </div>

        <div className="flex items-center">
          <div className="flex items-center text-base text-sembly-gray">
            {translate("landingPage.forms.emailVerificationForm.cantFind")}{" "}
            <span
              onClick={handleResendOtp}
              role="button"
              className={clsx("mx-4 font-medium", {
                "text-medium-purple-100 hover:underline": !disableResend,
                "cursor-not-allowed text-gray-40": disableResend,
              })}
              title={translate("landingPage.forms.emailVerificationForm.resend")}
            >
              {translate("landingPage.forms.emailVerificationForm.resend")}
            </span>
            {disableResend && <Countdown onComplete={() => setDisableResend(false)} />}
          </div>
          {!matchesMobile && (
            <Button
              type="submit"
              variant="primary"
              size="l"
              className="ml-auto h-[37px] w-[120px]"
              loading={isLoading}
              disabled={!isValid || !values.otp?.length}
            >
              {translate("landingPage.forms.emailVerificationForm.button")}
            </Button>
          )}
        </div>

        {matchesMobile && (
          <Button
            type="submit"
            variant="primary"
            size="l"
            fluid
            className="mt-16 h-[37px]"
            loading={isLoading}
            disabled={!isValid || !values.otp?.length}
          >
            {translate("landingPage.forms.emailVerificationForm.button")}
          </Button>
        )}
      </form>
    </div>
  );
};

const EmailVerificationDialog = (props: VerificationDialogProps) => {
  const [translate] = useTranslation("landingpage");
  const { matchesMobile } = useMedia();
  const { isEmailVerificationDialogOpen, isLoading } = useEmailVerificationDialogState();
  const { closeEmailVerificationDialog } = useEmailVerificationDialogActions();

  return (
    <Dialog open={isEmailVerificationDialogOpen} onClose={noop}>
      <Dialog.Container
        className={clsx("text-left text-sembly-gray sm:w-[532px] sm:p-24", { "p-24": !matchesMobile })}
        fullScreen={matchesMobile}
      >
        <Dialog.Header
          showClose
          showDivider={matchesMobile}
          onClose={!isLoading ? closeEmailVerificationDialog : noop}
          className="sm:mb-16 sm:!pb-10 sm:!pt-0"
        >
          <span className="text-lg font-medium text-sembly-gray">
            {translate("landingPage.forms.emailVerificationForm.title")}
          </span>
        </Dialog.Header>
        <EmailVerificationDialogContent {...props} />
      </Dialog.Container>
    </Dialog>
  );
};

EmailVerificationDialog.displayName = "EmailVerificationDialog";
export default EmailVerificationDialog;
