// ----------------------------------------------
// Used by CustomError
// ----------------------------------------------
export const ERROR_CODE_SERVICE_UNAVAILABLE = "FE-503";
export const ERROR_CODE_UNAUTHORIZED = "FE-401";
export const ERROR_CODE_FORBIDDEN = "FE-403";
export const ERROR_CODE_NOT_FOUND = "FE-404";
export const ERROR_CODE_INVALID_USERNAME_PASSWORD = "FE-401";
export const ERROR_CODE_FAILED_USER_LIST = "FE-1002";
export const ERROR_CODE_FAILED_STAKE_DATA_GET = "FE-1140";
export const ERROR_CODE_FAILED_CONSENSUS_DATA_SET = "FE-1150";
export const ERROR_CODE_FAILED_CONSENSUS_DATA_GET = "FE-1151";
export const ERROR_CODE_FAILED_USER_DEBUG_MODE_DATA_SET = "FE-1170";
export const ERROR_CODE_FAILED_USER_DEBUG_MODE_DATA_GET = "FE-1171";
export const ERROR_CODE_FAILED_NODE_ECONOMIC_VALUATION_METADATA_SET = "FE-1180";
export const ERROR_CODE_FAILED_NODE_ECONOMIC_VALUATION_METADATA_GET = "FE-1181";
export const ERROR_CODE_INVALID_SEARCH_QUERY = "FE-1201";
export const ERROR_CODE_FAILED_SEARCH_GET = "FE-1202";
export const ERROR_CODE_FAILED_SEARCH_AUTOCOMPLETE_SPACE_GET = "FE-1203";
export const ERROR_CODE_FAILED_SEARCH_AUTOCOMPLETE_KEYWORDS_GET = "FE-1204";
export const ERROR_CODE_FAILED_SEARCH_AUTOCOMPLETE_SECTIONS_GET = "FE-1205";
export const ERROR_CODE_FAILED_SPACE_SET = "FE-1301";
export const ERROR_CODE_FAILED_SPACE_GET = "FE-1302";
export const ERROR_CODE_FAILED_NOTIFICATION_COUNT_GET = "FE-1400";
export const ERROR_CODE_MULTI_THREADED_REQUESTS_NOT_SUPPORTED = "FE-10001";
export const ERROR_CODE_APPLICATION_VERSION_EXPIRED = "FE-10101";
export const ERROR_CODE_APPLICATION_VERSION_SESSION_EXPIRED = "FE-10102";
// Auth errors >15000
export const ERROR_CODE_MALFORMED_TOKEN = "FE-15000";
