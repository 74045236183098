import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import AuthenticationDialog from "@/src/pages/LandingPage/FormDialog/AuthenticationDialog";
import { selectors as signupDialogSelectors } from "@/src/stores/signupDialog";
import { slice as signupDialogSlice } from "@/src/stores/signupDialog";

export function SignupDialog() {
  const dispatch = useDispatch();
  const open = useSelector(signupDialogSelectors.isSignUpDialogOpenSelector);
  const selectedSpaceUrl = useSelector(signupDialogSelectors.selectedSpaceUrlSelector);
  const hideDialog = useCallback(() => {
    dispatch(signupDialogSlice.actions.closeSignupDialog());
  }, [dispatch]);

  return <AuthenticationDialog open={open} onClose={hideDialog} selectedSpaceUrl={selectedSpaceUrl} />;
}
