import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import Link from "next/link";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { LoginDialogContent } from "@/src/components/container/HeaderContainer/LoginDialog";
import {
  DialogContainer,
  DialogHeader,
  DialogHeaderJoinSpace,
} from "@/src/components/container/HeaderContainer/OnboardingDialogElm";
import BarSkeleton from "@/src/components/sembly-ui/components/BarSkeleton/BarSkeleton";
import Divider from "@/src/components/sembly-ui/components/Divider/Divider";
import Input from "@/src/components/sembly-ui/components/Input/Input";
import SemblyToast from "@/src/components/sembly-ui/components/SemblyToast/SemblyToast";
import Button from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyButton";
import Dialog from "@/src/components/sembly-ui/core/BaseComponent/Dialog/Dialog";
import { MailIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import * as Constant from "@/src/constants/AppConstant";
import { DEFAULT_NUM_PIONEERS_NEEDED, SPACE_STATE, SPACE_TYPE } from "@/src/domains/space/constants/Space";
import useTokenMetadata from "@/src/domains/user/components/Invite/hooks/useTokenMetadata";
import { selectors as inviteSelectors } from "@/src/domains/user/components/Invite/state";
import GoogleLoginButton from "@/src/domains/user/components/LoginDialog/GoogleLoginButton";
import { useAuthentication } from "@/src/hooks/authentication/AuthProvider";
import useSpaceInfo from "@/src/hooks/space/useGetSpaceByUrl";
import useMedia from "@/src/hooks/useMedia";
import { sendSignupTrackingEvent } from "@/src/hooks/useSignupTracking";
import useUserActivation from "@/src/hooks/useUserActivation";
import PasswordInput from "@/src/pages/LandingPage/FormDialog/PasswordInput";
import { useSpacePageUrlParameter } from "@/src/pages/SpaceLandingPage/utils/SpaceUrlParameterHelper";
import { selectors as signupDialogSelectors } from "@/src/stores/signupDialog";
import { MINIMUM_PASSWORD_STRENGTH_SCORE, getPasswordStrength } from "@/src/utils/auth/authUtils";
import { appendQueryString } from "@/src/utils/general/QueryStringUtil";
import noop from "@/src/utils/helpers/noop";
import { captureException } from "@/src/utils/logging/SentryLogging";

const getSignupSchema = (t: TFunction<"landingpage">) =>
  Yup.object().shape({
    email: Yup.string().email(t("validation.email.isEmail")).required(t("validation.email.required")),
    password: Yup.string().required(t("validation.password.required")),
  });

interface AuthenticationDialogProps {
  open: boolean;
  onClose?: () => void;
  isLandingPage?: boolean;
  onLoginLinkClick?: () => void;
  initialState?: number;
  selectedSpaceUrl?: string;
}

export enum AuthenticationDialogState {
  JOIN_SIGNUP_WALL,
  SIGNUP_FORM,
  LOGIN_FORM,
  VERIFY_EMAIL,
  DISPLAY_NAME_SELECTION,
  LOADING,
}

function AuthenticationDialog({
  open,
  onClose,
  onLoginLinkClick,
  initialState,
  selectedSpaceUrl,
}: AuthenticationDialogProps) {
  const [translate] = useTranslation("landingpage");
  const { matchesMobile } = useMedia();

  const [signupError, setSignupError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [enableValidateOnChange, setEnableValidateOnChange] = useState(false);
  const dialogInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const emailPrefill = useSelector(inviteSelectors.emailPrefillSelector);
  const invitationToken = useSelector(inviteSelectors.invitationTokenSelector);

  const isFromJoinAction = useSelector(signupDialogSelectors.isFromJoinActionSelector);
  const signUpDialogSpaceId = useSelector(signupDialogSelectors.signUpDialogSpaceIdSelector);
  const isFromJoinSpaceAction = isFromJoinAction && !initialState;
  const [viewState, setViewState] = useState(initialState || AuthenticationDialogState.SIGNUP_FORM);

  const { spaceUrl: spaceUrlPage } = useSpacePageUrlParameter();

  const passedSpaceUrl = selectedSpaceUrl ?? spaceUrlPage;

  const { data: spaceInfo } = useSpaceInfo(passedSpaceUrl);
  const spaceName = spaceInfo?.contentSpace.spaceName || "";
  const spaceUrl = spaceInfo?.contentSpace.spaceUrl;
  const spaceIcon = spaceInfo?.contentSpace.spaceIcon || spaceInfo?.contentSpace.spaceIconLarge || "";
  const isPrivate = spaceInfo?.contentSpace.spaceType === SPACE_TYPE.PRIVATE;
  const isRecruitingStage = spaceInfo?.contentSpace.spaceStatus === SPACE_STATE.RECRUITING;
  const numOfPioneersNeeded =
    (spaceInfo?.contentSpace?.enableSCFlow
      ? spaceInfo?.contentSpace?.upgradeRequirementSpec?.minPioneersToStartSimultaneousConsensus
      : spaceInfo?.contentSpace?.upgradeRequirementSpec?.minPioneersToPilot) ?? DEFAULT_NUM_PIONEERS_NEEDED;
  const memberCount = spaceInfo?.contentSpaceStats?.memberCount || 1;
  const pioneerPercentage = Math.min((memberCount / numOfPioneersNeeded) * 100, 100);

  const isEmailFieldFocused = dialogInputRef?.current === document.activeElement;

  const spaceJoinProps = useMemo(
    () => ({
      spaceIcon,
      spaceUrl,
      spaceName,
      isPrivate,
      ...(isRecruitingStage ? { spaceProgress: pioneerPercentage } : {}),
    }),
    [isPrivate, isRecruitingStage, pioneerPercentage, spaceIcon, spaceName, spaceUrl],
  );

  useEffect(() => {
    if (isFromJoinSpaceAction && open) {
      setViewState(AuthenticationDialogState.JOIN_SIGNUP_WALL);
    }
  }, [isFromJoinSpaceAction, open]);

  const handleJoinAfterSignupPrompt = useCallback(() => {
    const joinFromSignUpQueryString = appendQueryString(window.location.search, {
      [Constant.QUERY_STRING_PARAMETER_KEY.JOIN_FROM_SIGNUP]: Constant.QUERY_STRING_PARAMETER_VALUE.TRUE,
    });
    history.replace({ search: joinFromSignUpQueryString });
  }, [history]);

  const handleOnClose = useCallback(() => {
    if (typeof onClose === "function") {
      onClose();
      setViewState(initialState || AuthenticationDialogState.SIGNUP_FORM);
      return;
    }
    history.push(Constant.ROUTER_PATH_HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const { signup } = useAuthentication();

  const handleSignUpSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      const { email } = data;
      const { invitationToken, ...signUpOptions } = data;
      const isEmailChanged = emailPrefill ? emailPrefill !== email : false;

      const payload = {
        ...signUpOptions,
        options: {
          sendConfirmationOTP: true,
        },
      };

      if (invitationToken && !isEmailChanged) {
        payload.invitationToken = invitationToken.value?.split("#")?.[0];

        if (invitationToken.type === Constant.INVITATION_TYPES.EMAIL) {
          payload.tokenType = Constant.TOKEN_TYPES.EMAIL_INVITE;
          payload.options.sendConfirmationOTP = false;
        } else if (invitationToken.type === Constant.INVITATION_TYPES.LINK) {
          payload.tokenType = Constant.TOKEN_TYPES.LINK_INVITE;
        }
      }

      try {
        setIsLoading(true);
        if (isFromJoinSpaceAction || invitationToken.type === Constant.INVITATION_TYPES.EMAIL) {
          handleJoinAfterSignupPrompt();
        }
        await signup(payload);
      } catch (_e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = _e as any;
        setSignupError(e);
        if (e.response.data.reason) {
          setSignupError(e.response.data.reason);
        } else {
          SemblyToast.error(translate("formValidation.signupError"));
        }
        throw captureException(e);
      } finally {
        setIsLoading(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFromJoinSpaceAction],
  );

  const formik = useFormik<{
    email: string;
    password: string;
    invitationToken?: {
      value: string;
      type: string;
    };
    tokenType: string;
  }>({
    initialValues: {
      email: email || emailPrefill || "",
      password: "",
      invitationToken,
      tokenType: "",
    },
    validateOnChange: enableValidateOnChange,
    validationSchema: getSignupSchema(translate),
    onSubmit: ({ email, password, invitationToken, ...rest }) => {
      const passwordStrength = getPasswordStrength(password);
      if (passwordStrength?.score < MINIMUM_PASSWORD_STRENGTH_SCORE) {
        return;
      }

      return handleSignUpSubmit({
        email,
        password,
        invitationToken,
        options: rest,
      });
    },
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setEnableValidateOnChange(true);
      formik.handleSubmit();
    },
    [formik],
  );

  const { isProfileVerificationDialogOpen, isLoading: isLoadingUserActivation } = useUserActivation();

  // this hook is to change view state from loading to either verify or display name selection
  // need to use hook, because this need to wait sign up progress API call
  useEffect(() => {
    if (viewState === AuthenticationDialogState.LOADING) {
      if (isProfileVerificationDialogOpen) {
        setViewState(AuthenticationDialogState.DISPLAY_NAME_SELECTION);
      }
    }
  }, [isProfileVerificationDialogOpen, viewState]);

  const { tokenMetadata: metadataResponse, getTokenMetadata: fetchMetadata } = useTokenMetadata();

  useEffect(() => {
    if (invitationToken?.type === Constant.INVITATION_TYPES.EMAIL) {
      fetchMetadata({
        token: invitationToken?.value,
        tokenType: Constant.TOKEN_TYPES.EMAIL_INVITE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationToken?.type, invitationToken?.value]);

  useEffect(() => {
    if (metadataResponse && metadataResponse.status === 200) {
      setEmail(metadataResponse.inviteeEmail);
    }
  }, [metadataResponse]);

  const handleClickLogin = useCallback(() => {
    if (typeof onLoginLinkClick === "function") {
      onLoginLinkClick();
      return;
    }
    setViewState(AuthenticationDialogState.LOGIN_FORM);
  }, [onLoginLinkClick]);

  const generateAlreadyOnSemblyFooter = useCallback(
    (marginTop = "mt-16") => {
      return (
        <div className={`mx-auto ${marginTop} text-center text-sembly-gray`}>
          {/* @ts-ignore NOTE: somehow there's an error "No overload matches this call" */}
          {translate("landingPage.forms.signupForm.alreadyOnSemblyText")}
          <div
            className="inline font-medium text-medium-purple-100 hover:underline"
            role="button"
            onClick={handleClickLogin}
          >
            {translate("landingPage.forms.signupForm.login")}
          </div>
        </div>
      );
    },
    [handleClickLogin, translate],
  );

  const dialogHeaderJoinSpaceElm = useMemo(() => {
    return (
      <DialogHeaderJoinSpace
        handleClose={handleOnClose}
        signUpPrefix={translate("landingPage.forms.signupForm.signUpToJoin")}
        signUpSublabel={translate("landingPage.forms.signupForm.joinTheCommunity")}
        isJoinSpaceState={viewState === AuthenticationDialogState.JOIN_SIGNUP_WALL}
      />
    );
  }, [handleOnClose, translate, viewState]);

  const renderJoinSignUpWall = () => {
    return (
      <>
        {dialogHeaderJoinSpaceElm}
        <Button
          variant="secondary"
          size="l"
          fluid
          icon={MailIcon}
          iconClassName="w-20 h-20"
          className="mb-8 mt-24 h-38 font-medium"
          onClick={() => {
            sendSignupTrackingEvent("CLICK_SIGNUP_WITH_EMAIL_CTA", {
              spaceId: signUpDialogSpaceId,
            });
            setViewState(AuthenticationDialogState.SIGNUP_FORM);
          }}
        >
          {translate("landingPage.forms.signupForm.signUpWithEmail")}
        </Button>
        <GoogleLoginButton />
        {generateAlreadyOnSemblyFooter("mt-8")}
      </>
    );
  };

  const renderSignUpForm = () => {
    const invalidEmailErrorType = !isEmailFieldFocused && Boolean(formik.values.email) && Boolean(formik.errors.email);
    const nonInvalidEmailErrorType =
      (Boolean(formik.errors.email) || Boolean(signupError)) && !invalidEmailErrorType && enableValidateOnChange;

    return (
      <>
        {isFromJoinSpaceAction && spaceInfo ? (
          dialogHeaderJoinSpaceElm
        ) : (
          <DialogHeader handleClose={handleOnClose} size="s" />
        )}
        {!isFromJoinSpaceAction && (
          <>
            <div className="mb-2 mt-8 text-xl font-bold text-sembly-gray">
              <span>{translate("landingPage.forms.signupForm.formHeader")}</span>
            </div>
            <div className="text-xs text-gray-80">{translate("landingPage.forms.signupForm.subtitle")}</div>
          </>
        )}

        <form onSubmit={handleSubmit} noValidate autoComplete="off" className="mt-12 flex flex-col md:mt-24">
          <div className="mb-8">
            <Input
              ref={dialogInputRef}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              placeholder={translate("landingPage.forms.signupForm.email")}
              className={clsx("w-full !py-6 placeholder:italic", {
                "!border-dark-red-60": nonInvalidEmailErrorType || invalidEmailErrorType,
              })}
              error={nonInvalidEmailErrorType || invalidEmailErrorType}
            />
            {nonInvalidEmailErrorType && (
              <div className="mt-4 text-xs text-dark-red-100">
                {formik.errors.email ? formik.errors.email : signupError && translate("validation.email.alreadyInUse")}
              </div>
            )}
            {invalidEmailErrorType && (
              <div className="mt-4 text-xs text-dark-red-100">{translate("validation.email.isEmail")}</div>
            )}
          </div>
          <PasswordInput
            onChange={formik.handleChange}
            value={formik.values.password}
            validateOnChange
            shouldShowEmptyError={Boolean(formik.touched.password) && Boolean(formik.errors.password)}
          />

          <div className="mb-8 mt-16 text-center text-xs text-gray-80">
            {`${translate("landingPage.forms.signupForm.footerText")} `}
            <Link
              href={Constant.ROUTER_PATH_TERMS_OF_SERVICE_PAGE}
              passHref
              target="_blank"
              className="inline font-medium text-medium-purple-100 hover:underline"
            >
              {translate("landingPage.forms.signupForm.term")}
            </Link>
            {` ${translate("landingPage.forms.signupForm.and")} `}
            <Link
              href={Constant.ROUTER_PATH_PRIVACY_POLICY_PAGE}
              passHref
              target="_blank"
              className="inline font-medium text-medium-purple-100 hover:underline"
            >
              {translate("landingPage.forms.signupForm.policy")}
            </Link>
            .
          </div>

          <Button
            loading={isLoading || isLoadingUserActivation}
            variant="primary"
            size="l"
            fluid
            className="-mt-1 h-[33px] !rounded-[5px]"
          >
            {translate("landingPage.forms.signupForm.submit")}
          </Button>
          <Divider text="or" className="my-16" />
          <GoogleLoginButton className="h-[33px] !rounded-[5px]" />
          {generateAlreadyOnSemblyFooter()}
        </form>
      </>
    );
  };

  const renderViewBasedOnState = () => {
    switch (viewState) {
      case AuthenticationDialogState.JOIN_SIGNUP_WALL:
        return renderJoinSignUpWall();
      case AuthenticationDialogState.LOGIN_FORM:
        return (
          <LoginDialogContent
            ref={dialogInputRef}
            handleClose={handleOnClose}
            handleSignupClick={() => setViewState(AuthenticationDialogState.SIGNUP_FORM)}
            isFromJoinAction={isFromJoinSpaceAction}
            spaceInfo={spaceInfo ? spaceJoinProps : undefined}
          />
        );
      case AuthenticationDialogState.LOADING:
        return (
          <div className="mt-12">
            <BarSkeleton numBars={6} />
          </div>
        );
      default:
        return renderSignUpForm();
    }
  };

  useEffect(() => {
    if (!isEmailFieldFocused && Boolean(formik.values.email)) {
      setEnableValidateOnChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailFieldFocused]);

  const shouldDisableClose = [
    AuthenticationDialogState.DISPLAY_NAME_SELECTION,
    AuthenticationDialogState.VERIFY_EMAIL,
    AuthenticationDialogState.LOADING,
  ].includes(viewState);

  const isFullScreen =
    matchesMobile &&
    [AuthenticationDialogState.VERIFY_EMAIL, AuthenticationDialogState.DISPLAY_NAME_SELECTION].includes(viewState);

  return (
    <Dialog open={open} onClose={shouldDisableClose ? noop : handleOnClose} initialFocus={dialogInputRef}>
      <DialogContainer
        fullScreen={isFullScreen}
        className={clsx("max-w-full bg-white md:w-[480px]", {
          "w-[343px]": !isFullScreen,
          "min-h-[546px] sm:min-h-0": !isFullScreen && isFromJoinSpaceAction,
        })}
      >
        {renderViewBasedOnState()}
      </DialogContainer>
    </Dialog>
  );
}

export default AuthenticationDialog;
