import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import React from "react";

import * as RadixDialog from "@radix-ui/react-dialog";
import clsx from "clsx";

import LinkButton from "@/src/components/sembly-ui/core/BaseComponent/Button/LegacyLinkButton";

export const DialogHeaderDefaultAction = (props: Omit<ComponentProps<typeof LinkButton>, "size">) => {
  return <LinkButton className="!no-underline" size="s" {...props} />;
};

export interface DialogHeaderWithSideActionsProps {
  leftAction?: ReactNode;
  rightAction?: ReactNode;
  className?: string;
}

function DialogHeaderWithSideActions({
  children,
  leftAction,
  rightAction,
  className,
}: PropsWithChildren<DialogHeaderWithSideActionsProps>) {
  return (
    <RadixDialog.Title
      className={clsx(
        "flex h-48 min-w-[200px] items-center justify-between py-14 pl-16 pr-14 sm:h-auto sm:px-0 sm:pb-16 sm:pt-6 md:min-w-[400px]",
        "text-left text-sembly-gray",
        "border-b border-light-gray-100",
        className,
      )}
    >
      <div className="flex min-h-[10px] cursor-pointer justify-start text-left">{leftAction}</div>
      <div className="mx-auto text-center text-base font-medium text-sembly-gray">{children}</div>
      <div className="flex min-h-[10px] cursor-pointer justify-end text-right">{rightAction}</div>
    </RadixDialog.Title>
  );
}

export default DialogHeaderWithSideActions;
